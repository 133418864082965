import {
  Alert,
  Button,
  Flex,
  Heading,
  Input,
  InputTypes,
  Loading,
  Modal,
  Option,
  showAlertModalError,
} from '@conteg/ui';
import Notification from 'components/notification/notification';
import { Select } from 'components/select/select';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  BlockingReservationReason,
  ServiceTypeEnum,
  StorageUnitDocument,
  useCreateBlockingReservationRequestV2Mutation,
  useTenantsQuery,
} from 'types/generated/graphql';
import { usePoint } from 'utils/device/device-store';
import { useInvalidateQueries } from 'utils/hooks/use-ivalidate-queries';

type FormInputs = {
  phoneNumber: string;
  tenantSubjectId: string;
  projectId: string;
  serviceType: string;
  reason: BlockingReservationReason;
  reasonDescription: string;
};

const blockingReservationReasonOptions: Option[] = [
  {
    label: i18n.t('BoxCannotBeClosed'),
    value: BlockingReservationReason.BoxCannotBeClosed,
  },
  {
    label: i18n.t('BoxDidNotOpen'),
    value: BlockingReservationReason.BoxDidNotOpen,
  },
  { label: i18n.t('BrokenDoor'), value: BlockingReservationReason.BrokenDoor },
  { label: i18n.t('BrokenLock'), value: BlockingReservationReason.BrokenLock },
  { label: i18n.t('DirtyBox'), value: BlockingReservationReason.DirtyBox },
  {
    label: i18n.t('MissingContent'),
    value: BlockingReservationReason.MissingContent,
  },
  { label: i18n.t('Other'), value: BlockingReservationReason.Other },
  {
    label: i18n.t('UnidentifiedContent'),
    value: BlockingReservationReason.UnidentifiedContent,
  },
  {
    label: i18n.t('WrongContent'),
    value: BlockingReservationReason.WrongContent,
  },
];

const CreateBlockingReservation = () => {
  const { t } = useTranslation();
  const { pointId } = usePoint();
  const { storageUnitId } = useParams<{ storageUnitId?: string }>();
  const invalidate = useInvalidateQueries();
  const [formNotice, setFormNotice] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);

  const formActions = useForm<FormInputs>({
    defaultValues: {
      phoneNumber: '',
      reason: undefined,
      reasonDescription: undefined,
    },
  });

  const {
    handleSubmit,
    reset,
    register,
    watch,
    setValue,
    formState: { errors },
  } = formActions;

  const { data: tenants, error: tenantsError } = useTenantsQuery(
    {
      pointId: pointId as string,
    },
    {
      enabled: !!pointId,
      gcTime: 0,
      staleTime: 0,
      select: (data) => data.tenants,
    }
  );

  const tenantOptions: Option[] =
    tenants?.map((data) => ({
      label: data?.tenantName ?? '',
      value: data?.tenantSubjectId ?? '',
    })) ?? [];

  const watchTenantSubjectId = watch('tenantSubjectId');
  const watchReason = watch('reason');

  const serviceType = tenants?.find(
    (t) => t != null && t.tenantSubjectId === watchTenantSubjectId
  )?.tenantServiceType;

  useEffect(() => {
    if (watchTenantSubjectId && tenants) {
      const selectedTenant = tenants.find(
        (tenant) => tenant?.tenantSubjectId === watchTenantSubjectId
      );

      if (selectedTenant) {
        setValue('projectId', selectedTenant.projectId);
        setValue('serviceType', selectedTenant.tenantServiceType);
      }
    }
  }, [setValue, tenants, watchTenantSubjectId]);

  const { mutate: createBlockingReservationMutation, isPending: isCreating } =
    useCreateBlockingReservationRequestV2Mutation();

  const handleCreateReservation: SubmitHandler<FormInputs> = ({
    phoneNumber,
    tenantSubjectId,
    serviceType,
    projectId,
    reason,
    reasonDescription,
  }) => {
    setFormNotice('');
    createBlockingReservationMutation(
      {
        input: {
          pointId: pointId as string,
          storageUnitId: storageUnitId as string,
          phoneNumber,
          tenantSubjectId,
          serviceType: serviceType as ServiceTypeEnum,
          projectId,
          reason,
          reasonDescription,
        },
      },
      {
        onSuccess: () => {
          alert(t('Page.StorageUnit.Actions.Blocking.Reservation.Success'));
          invalidate([StorageUnitDocument]);
          setOpenModal(false);
          reset();
        },
        onError: (error) => {
          if (error?.toString().includes('PhoneNumber')) {
            setFormNotice(
              'Page.StorageUnit.Actions.Blocking.Reservation.Error.PhoneNumber'
            );
            return;
          }
          showAlertModalError(
            t('Page.StorageUnit.Actions.Blocking.Reservation.Error'),
            error
          );
        },
      }
    );
  };

  if (isCreating) {
    return (
      <Flex alignItems="center" justifyContent="center">
        <Loading
          text={t(
            'Page.StorageUnit.Actions.Blocking.Reservation.Create.Loading'
          )}
        />
      </Flex>
    );
  }

  if (tenantsError) {
    return (
      <Alert
        error={tenantsError}
        title={t('Error.TenantsLoadError')}
        type="error"
      />
    );
  }

  return (
    <>
      <Modal
        testId="create-reservation-modal"
        size="l"
        showCloseButton={true}
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
      >
        <FormProvider {...formActions}>
          <form onSubmit={handleSubmit(handleCreateReservation)}>
            <Flex flexDirection="column" alignItems="center" gap="5rem">
              <Heading
                title={t(
                  'Page.StorageUnit.Actions.Blocking.Reservation.Create.Heading'
                )}
                variant="h2"
              />
              {formNotice && (
                <Notification
                  testId="create-blocking-reservation-notification"
                  message={formNotice}
                />
              )}
              <Select
                name="reason"
                options={blockingReservationReasonOptions}
                label={t(
                  'Page.StorageUnit.Actions.BlockingReservation.Select.Reason'
                )}
                isNullable={false}
                required
                error={errors?.reason || undefined}
              />
              {watchReason === BlockingReservationReason.Other && (
                <Input
                  testId="reasonDescription"
                  type={InputTypes.TEXT}
                  label={t('Page.StorageUnit.Actions.Input.ReasonDescription')}
                  placeholder={t(
                    'Page.StorageUnit.Actions.Input.ReasonDescription'
                  )}
                  error={
                    errors.reasonDescription?.message &&
                    t(errors.reasonDescription.message)
                  }
                  {...register('reasonDescription', {
                    required: 'Error.Form.Required',
                  })}
                />
              )}
              <Select
                name="tenantSubjectId"
                options={tenantOptions}
                label={t(
                  'Page.StorageUnit.Actions.BlockingReservation.Select.Tenant'
                )}
                isNullable={false}
                required
                error={errors?.tenantSubjectId || undefined}
              />
              <Input
                testId="phoneNumber"
                type={InputTypes.TEL}
                label={t('Page.StorageUnit.Actions.Input.PhoneNumber')}
                placeholder={t('Page.StorageUnit.Actions.Input.PhoneNumber')}
                error={
                  errors.phoneNumber?.message && t(errors.phoneNumber.message)
                }
                {...register('phoneNumber', { required: false })}
              />
              {(watchReason === BlockingReservationReason.UnidentifiedContent ||
                watchReason === BlockingReservationReason.WrongContent) &&
                serviceType == ServiceTypeEnum.Delivery && (
                  <Alert
                    type="warning"
                    testId="blockingReservation-delivery-stockout-alert"
                    message={t(
                      'Page.StorageUnit.Actions.BlockingReservation.UnidentifiedContent.Warning'
                    )}
                  ></Alert>
                )}
              <Flex alignItems="center" gap="5rem">
                <Button
                  testId="create-reservation-confirmation-button"
                  variant="primary"
                  size="xl"
                  title={t(
                    'Page.StorageUnit.Actions.Input.BlockingReservation.Create.ConfirmButton'
                  )}
                  disabled={isCreating}
                  type="submit"
                />
                <Button
                  testId="close-create-reservation-modal-button"
                  variant="danger"
                  size="xl"
                  type="button"
                  title={t(
                    'Page.StorageUnit.Actions.Input.BlockingReservation.Create.CancelButton'
                  )}
                  onClick={() => setOpenModal(false)}
                />
              </Flex>
            </Flex>
          </form>
        </FormProvider>
      </Modal>
      <Button
        testId="create-reservation-button"
        variant="primary"
        size="xl"
        title={t('Page.StorageUnit.Actions.BlockingReservation.Create')}
        onClick={() => setOpenModal(true)}
      />
    </>
  );
};

export default CreateBlockingReservation;
