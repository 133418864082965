import { Flex, Icon, Tooltip } from '@conteg/ui';
import { useTranslation } from 'react-i18next';
import { StorageUnitFeature } from 'types/generated/graphql';

type StorageUnitFeaturesCompactProps = {
  storageUnitFeatures: StorageUnitFeature[];
};

const ICON_SIZE = '7rem';

const StorageUnitFeaturesCompact = ({
  storageUnitFeatures,
}: StorageUnitFeaturesCompactProps) => {
  const { t } = useTranslation();

  const getTooltip = (feature: StorageUnitFeature) => {
    switch (feature) {
      case StorageUnitFeature.Cooling:
        return (
          <Tooltip
            testId="storage-unit-reservation-feature-tooltip-cooling"
            content={t('Page.StorageUnitList.Table.StorageUnitFeature.Cooling')}
            key={feature}
          >
            <Icon name="snowFlake" width={ICON_SIZE} height={ICON_SIZE} />
          </Tooltip>
        );
      case StorageUnitFeature.AcSocket:
        return (
          <Tooltip
            testId="storage-unit-reservation-feature-tooltip-acsocket"
            content={t(
              'Page.StorageUnitList.Table.StorageUnitFeature.AcSocket'
            )}
            key={feature}
          >
            <Icon name="chargingCable" width={ICON_SIZE} height={ICON_SIZE} />
          </Tooltip>
        );
    }
  };

  return (
    <Flex gap="1rem" alignItems="center">
      {storageUnitFeatures.map(getTooltip)}
    </Flex>
  );
};

export default StorageUnitFeaturesCompact;
