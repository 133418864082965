import {
  Box,
  Button,
  Flex,
  InputTypes,
  Loading,
  Option,
  styled,
  Text,
} from '@conteg/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { getValidationSchema } from 'components/create-delivery-reservation/get-validation-schema';
import { FormInput } from 'components/input/input';
import { FormCheckbox } from 'components/inputs/form-checkbox';
import { PolicyGuardWrapper } from 'components/policy-guard/policy-guard';
import { Select } from 'components/select/select';
import { DateTime } from 'luxon';
import { customAlphabet } from 'nanoid';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const trackingIdentifierGenerator = customAlphabet('1234567890abcdef', 10);
const phoneNumberGenerator = customAlphabet('1234567890', 6);

type Values = {
  width: number;
  height: number;
  depth: number;
  storeDuration: number;
  trackingIdentifier: string;
  storedFromDate: string;
  storedFromTime: string;
  stockInToDate: string;
  stockInToTime: string;
  generatePinAutomatically: boolean;
  phoneNumber: string;
  tenantSubjectId: string;
};

type CreateReservationFormProps = {
  onSubmit: (values: Values) => void;
  isLoading?: boolean;
  maxWidth: number;
  maxHeight: number;
  maxDepth: number;
  tenantsOptions: Option[];
};

export const CreateDeliveryReservationForm = ({
  onSubmit,
  isLoading,
  maxWidth,
  maxHeight,
  maxDepth,
  tenantsOptions,
}: CreateReservationFormProps) => {
  const { t } = useTranslation();

  const defaultValues = {
    trackingIdentifier: trackingIdentifierGenerator(),
    generatePinAutomatically: true,
    storedFromTime: '00:00',
    stockInToTime: '00:00',
    tenantSubjectId:
      tenantsOptions.length === 1 ? tenantsOptions[0].value : undefined,
  };

  const formActions = useForm<Values>({
    resolver: zodResolver(
      getValidationSchema({ maxWidth, maxHeight, maxDepth, t })
    ),
    defaultValues,
  });

  useEffect(() => {
    formActions.reset(defaultValues);
    // No need to add dependencies here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const storedFrom = DateTime.now().plus({ minutes: 5 });
  const stockInToDate = DateTime.now().plus({ hour: 1, minutes: 5 });

  const fillTestingData = () => {
    const testData: Values = {
      tenantSubjectId: tenantsOptions[0].value,
      trackingIdentifier: trackingIdentifierGenerator(),
      phoneNumber: `+420732${phoneNumberGenerator()}`,
      generatePinAutomatically: true,
      depth: Math.round(Math.random() * maxDepth - 1),
      height: Math.round(Math.random() * maxHeight - 1),
      width: Math.round(Math.random() * maxWidth - 1),
      storedFromDate: storedFrom.toISODate(),
      storedFromTime: storedFrom.toFormat('HH:mm'),
      stockInToDate: stockInToDate.toISODate(),
      stockInToTime: stockInToDate.toFormat('HH:mm'),
      storeDuration: 12,
    };

    formActions.reset(testData);
  };

  return (
    <Box>
      <FormProvider {...formActions}>
        <form onSubmit={formActions.handleSubmit(onSubmit)}>
          <Container>
            <Box>
              <Text
                variant="highlight"
                content={t('CreateDeliveryReservation.Form.PackageDimensions')}
              />
              <Flex gap="2rem" marginTop="1rem" marginBottom="5rem">
                <FormInput<Values>
                  type={InputTypes.NUMBER}
                  name="width"
                  label={t('CreateDeliveryReservation.Form.Width')}
                  placeholder={t('CreateDeliveryReservation.Form.Width')}
                />
                <FormInput<Values>
                  type={InputTypes.NUMBER}
                  name="height"
                  label={t('CreateDeliveryReservation.Form.Height')}
                  placeholder={t('CreateDeliveryReservation.Form.Height')}
                />
                <FormInput<Values>
                  type={InputTypes.NUMBER}
                  name="depth"
                  label={t('CreateDeliveryReservation.Form.Depth')}
                  placeholder={t('CreateDeliveryReservation.Form.Depth')}
                />
              </Flex>
              <Text
                variant="highlight"
                content={t(
                  'CreateDeliveryReservation.Form.PackageStoreDuration'
                )}
              />

              <Flex flexDirection="column" marginBottom="4rem">
                <Text
                  content={t('CreateDeliveryReservation.Form.StoredFrom')}
                />
                <TimeContainer>
                  <FormInput
                    type={InputTypes.DATE}
                    label={t('Day')}
                    placeholder="dd.mm.yyyy"
                    name="storedFromDate"
                  />
                  <FormInput
                    name="storedFromTime"
                    type={InputTypes.TIME}
                    label={t('Hour')}
                    placeholder="hh:mm"
                  />
                </TimeContainer>
              </Flex>
              <Flex flexDirection="column" marginBottom="4rem">
                <Text content={t('CreateDeliveryReservation.Form.StockInTo')} />
                <TimeContainer>
                  <FormInput
                    type={InputTypes.DATE}
                    label={t('Day')}
                    placeholder="dd.mm.yyyy"
                    name="stockInToDate"
                  />
                  <FormInput
                    name="stockInToTime"
                    type={InputTypes.TIME}
                    label={t('Hour')}
                    placeholder="hh:mm"
                  />
                </TimeContainer>
              </Flex>
              <Flex marginTop="2rem" flexDirection="column">
                <Text
                  content={t(
                    'CreateDeliveryReservation.Form.PackageStoreDuration'
                  )}
                />
                <FormInput
                  type={InputTypes.NUMBER}
                  name="storeDuration"
                  placeholder={t(
                    'CreateDeliveryReservation.Form.PackageStoreDuration'
                  )}
                  label={t(
                    'CreateDeliveryReservation.Form.PackageStoreDuration'
                  )}
                />
              </Flex>
            </Box>
            <Flex flexDirection="column" gap="5rem">
              <Text
                variant="highlight"
                content={t('CreateDeliveryReservation.Form.Access')}
              />
              <FormInput
                disabled
                label={t('CreateDeliveryReservation.Form.PackageNumber')}
                placeholder={t('CreateDeliveryReservation.Form.PackageNumber')}
                name="trackingIdentifier"
              />
              <FormInput
                label={t('CreateDeliveryReservation.Form.PhoneNumber')}
                placeholder={t('CreateDeliveryReservation.Form.PhoneNumber')}
                name="phoneNumber"
              />
              <FormCheckbox
                label={t(
                  'CreateDeliveryReservation.Form.GeneratePinAutomatically'
                )}
                name="generatePinAutomatically"
                disabled
              />
              <Select
                name="tenantSubjectId"
                isNullable={false}
                options={tenantsOptions}
                label={t('CreateDeliveryReservation.Form.Tenant')}
                error={formActions.formState.errors.tenantSubjectId}
              />
            </Flex>
          </Container>
          <Flex justifyContent="flex-end" width="100%" gap="3rem">
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <PolicyGuardWrapper action="createCreateDeliveryTestData">
                  <Button
                    onClick={fillTestingData}
                    type="button"
                    title={t('CreateDeliveryReservation.Form.FillTestingData')}
                  />
                </PolicyGuardWrapper>
                <Button
                  type="submit"
                  disabled={isLoading}
                  title={t('CreateDeliveryReservation.Form.CreateReservation')}
                />
              </>
            )}
          </Flex>
        </form>
      </FormProvider>
    </Box>
  );
};

const Container = styled.div`
  gap: 10rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const TimeContainer = styled.div`
  gap: 2rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin-bottom: 3rem;
`;
