import Error from 'components/error/error';
import { IS_KIOSK } from 'config';

const ErrorFallback = () => {
  return (
    <Error message="Error.Fallback.Info" showToCustomerAppButton={IS_KIOSK} />
  );
};

export default ErrorFallback;
