import { parseFormDateToDateTime } from 'components/create-delivery-reservation/delivery-reservation-pin/utils';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import { z } from 'zod';

type GetValidationSchemaParams = {
  maxWidth: number;
  maxHeight: number;
  maxDepth: number;
  t: TFunction;
};

const phoneNumberRegex = /^\+?[0-9]{12}$/;

export const requiredString = z
  .string({ required_error: 'Validations.Required' })
  .min(1, 'Validations.Required');

export const getValidationSchema = ({
  maxDepth,
  maxHeight,
  maxWidth,
  t,
}: GetValidationSchemaParams) => {
  const getDimensionType = (max: number) =>
    z
      .number({
        required_error: t('Validations.Required'),
        invalid_type_error: t('Validations.Number.Int'),
      })
      .min(
        1,
        t('CreateDeliveryReservation.Form.MinValueValidation', {
          value: 0,
        }) as string
      )
      .max(
        max,
        t('CreateDeliveryReservation.Form.MaxIs', {
          value: max,
        }) as string
      );

  return z
    .object({
      width: getDimensionType(maxWidth),
      height: getDimensionType(maxHeight),
      depth: getDimensionType(maxDepth),
      storeDuration: z
        .number({ required_error: t('Validations.Required') })
        .int()
        .positive(
          t('CreateDeliveryReservation.Form.MinValueValidation', {
            value: 1,
          }) as string
        )
        .max(
          72,
          t('CreateDeliveryReservation.Form.MaxIs', {
            value: 72,
          }) as string
        ),
      trackingIdentifier: z.string(),
      tenantSubjectId: requiredString,
      storedFromDate: requiredString,
      storedFromTime: requiredString,
      stockInToDate: requiredString,
      stockInToTime: requiredString,
      generatePinAutomatically: z.boolean(),
      phoneNumber: z
        .string()
        .regex(phoneNumberRegex, {
          message: t('CreateDeliveryReservation.Form.PhoneNumberValidation'),
        })
        .or(z.literal('')),
    })
    .superRefine(
      (
        { storedFromDate, storedFromTime, stockInToDate, stockInToTime },
        ctx
      ) => {
        const storedFrom = parseFormDateToDateTime(
          storedFromDate,
          storedFromTime
        );

        const stockInTo = parseFormDateToDateTime(stockInToDate, stockInToTime);

        if (storedFrom.valueOf() < DateTime.now().valueOf()) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['storedFromDate'],
            message: t(
              'CreateDeliveryReservation.Form.StoredFromMustBeInTheFuture'
            ),
          });
        }

        if (storedFrom.valueOf() > stockInTo.valueOf()) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['storedFromDate'],
            message: t(
              'CreateDeliveryReservation.Form.StoredFromMustBeBeforeStockInTo'
            ),
          });
        }
      }
    );
};
