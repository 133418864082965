import { useAuth } from '@conteg/auth';
import { CUSTOM_AUTH_TOKEN, IS_KIOSK } from 'config';
import LoginKiosk from 'pages/login/components/login-kiosk';
import LoginWeb from 'pages/login/components/login-web';
import { Navigate } from 'react-router-dom';
import { appRoutes } from 'utils/routing/routes';

const search = window.location.search;

const Login = () => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated || CUSTOM_AUTH_TOKEN) {
    return <Navigate to={`${appRoutes.home}${search}`} />;
  }

  if (IS_KIOSK) return <LoginKiosk />;

  return <LoginWeb />;
};

export default Login;
