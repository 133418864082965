import { Flex } from '@conteg/ui';
import OneTimePassword, {
  OneTimePasswordProps,
} from 'pages/storage-unit-detail/components/actions/one-time-password/one-time-password';

import EndCurrentReservation from '../end-current-reservation';

type ShowOneTimePasswordProps = {
  storageUnitId: string;
  oneTimePassword: OneTimePasswordProps;
};

const ShowOneTimePassword = ({
  storageUnitId,
  oneTimePassword,
}: ShowOneTimePasswordProps) => {
  return (
    <Flex gap="8rem" flexDirection="column" alignItems="center">
      <OneTimePassword {...oneTimePassword} />
      <EndCurrentReservation storageUnitId={storageUnitId} />
    </Flex>
  );
};

export default ShowOneTimePassword;
