enum RouteNames {
  HOME = '/home',
  STORAGE_UNIT_DETAIL = '/storage-unit/:storageUnitId',
  WHITE_LIST = '/whitelist',
}

export const appRoutes = {
  home: RouteNames.HOME,
  storageUnitDetail: RouteNames.STORAGE_UNIT_DETAIL,
};

export const substituteRouteParams = (
  route: string,
  params: Record<string, string>
) => {
  let replacedRoute = route;

  Object.entries(params).forEach(([key, value]) => {
    replacedRoute = replacedRoute.replace(`:${key}`, value);
  });

  return replacedRoute;
};
