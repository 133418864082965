import { Box, Text } from '@conteg/ui';
import {
  OpenModal,
  OpenModalTypes,
} from 'pages/storage-unit-detail/components/actions/allocation-actions';
import { OneTimePasswordProps } from 'pages/storage-unit-detail/components/actions/one-time-password/one-time-password';
import ForcedOpenUnitWithReservation from 'pages/storage-unit-detail/components/actions/open-unit/forced-open-unit-with-reservation';
import OpenWithPin from 'pages/storage-unit-detail/components/actions/open-unit/open-with-pin';
import RequestMaintenance from 'pages/storage-unit-detail/components/actions/request-maintenance/request-maintenance';
import UpdateUnitStatus from 'pages/storage-unit-detail/components/actions/update-unit-status/update-unit-status';
import { useTranslation } from 'react-i18next';
import {
  StorageUnitDtoFragment,
  StorageUnitStatus,
} from 'types/generated/graphql';

import ChangeBlockingReservationTenantSubjectId from './change-blocking-reservation-tenant-subject-id/change-blocking-reservation-tenant-subject-id';
import OpenWithoutPin from './open-unit/open-without-pin';
import ResendSms from './resend-sms/resend-sms';
import TerminateAllocation from './terminate-allocation/terminate-allocation';
import UpdateReservationState from './update-reservation-state/update-reservation-state';

type ActionsModalContentProps = {
  contentType: OpenModal;
  storageUnit: StorageUnitDtoFragment;
  oneTimePassword?: OneTimePasswordProps;
};

const ActionsModalContent = ({
  contentType,
  storageUnit,
}: ActionsModalContentProps) => {
  const { t } = useTranslation();
  const tenantSubjectId = storageUnit.allocation?.reservation?.tenantSubjectId;
  const trackingIdentifier =
    storageUnit.allocation?.content?.trackingIdentifiers?.[0]?.identifier;
  const contentId = storageUnit.allocation?.content?.id;
  const reservationId = storageUnit.allocation?.reservation?.id;
  const serviceType = storageUnit.allocation?.reservation?.serviceType;
  const isDeliverySubmission =
    storageUnit.allocation?.reservation?.isDeliverySubmission;
  const storageUnitId = storageUnit.storageUnit.id;

  switch (contentType) {
    case OpenModalTypes.ForcedOpenStorageUnitWithReservation:
      return (
        <ForcedOpenUnitWithReservation
          storageUnitId={storageUnit.storageUnit.id}
        />
      );
    case OpenModalTypes.OpenStorageUnit:
      return (
        <OpenWithPin
          storageUnitId={storageUnit.storageUnit.id}
          reservationStatus={storageUnit.allocation?.reservation?.status}
          serviceType={serviceType}
          tenantSubjectId={tenantSubjectId}
          openWithoutVerifyStep={false}
        />
      );
    case OpenModalTypes.OpenStorageUnitWithoutPin:
      return <OpenWithoutPin storageUnitId={storageUnit.storageUnit.id} />;
    case OpenModalTypes.UpdateStatus:
      return (
        <UpdateUnitStatus
          storageUnitId={storageUnit.storageUnit.id}
          status={
            storageUnit.storageUnit.storageUnitStatus ===
            StorageUnitStatus.Broken
              ? StorageUnitStatus.Usable
              : StorageUnitStatus.Broken
          }
        />
      );
    case OpenModalTypes.RequestMaintenance:
      return <RequestMaintenance storageUnitId={storageUnit.storageUnit.id} />;
    case OpenModalTypes.TerminateAllocation:
      return <TerminateAllocation storageUnitId={storageUnit.storageUnit.id} />;
    case OpenModalTypes.UpdateReservationState:
      return (
        <UpdateReservationState
          tenantSubjectId={tenantSubjectId as string}
          trackingIdentifier={trackingIdentifier}
          contentId={contentId as string}
          serviceType={serviceType}
          isDeliverySubmission={isDeliverySubmission}
          storageUnitId={storageUnitId}
        />
      );
    case OpenModalTypes.ResendSms:
      return <ResendSms reservationId={reservationId as string} />;
    case OpenModalTypes.ChangeBlockingReservationTenantSubjectId:
      return (
        <ChangeBlockingReservationTenantSubjectId
          reservationId={reservationId as string}
        />
      );
  }

  if (contentType === null) return null;

  return (
    <Box textAlign="center">
      <Text
        testId="modal-content-error"
        variant="highlight"
        content={t('Page.StorageUnit.Actions.ModalError')}
      />
    </Box>
  );
};

export default ActionsModalContent;
