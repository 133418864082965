import { IS_KIOSK } from 'config';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type DeviceStore = {
  pointId: string | null;
  setPointId: (pointId: string | null) => void;
};

export const useDeviceStore = create<DeviceStore>()(
  IS_KIOSK
    ? (set) => ({
        pointId: null,
        setPointId: (pointId: string | null) =>
          set(() => ({
            pointId: pointId ?? null,
          })),
      })
    : persist<DeviceStore>(
        (set) => ({
          pointId: null,
          setPointId: (pointId: string | null) =>
            set(() => ({
              pointId: pointId ?? null,
            })),
        }),
        {
          name: 'device-store',
        }
      )
);

export const usePoint = () =>
  useDeviceStore((state: DeviceStore) => ({
    pointId: state.pointId,
  }));
