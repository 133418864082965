import { css, styled } from '@conteg/ui';
import { useTranslation } from 'react-i18next';
import { StorageUnitStatus } from 'types/generated/graphql';

type Variant = string | undefined;

// Create a union type for valid filter options
type StatusBadgeVariants =
  | 'EMPTY'
  | 'OCCUPIED'
  | typeof StorageUnitStatus.Usable
  | typeof StorageUnitStatus.Blocked
  | typeof StorageUnitStatus.Broken;

// Define the enum with the valid options
export const StorageUnitFilterOptionsEnum: Record<
  StatusBadgeVariants,
  StatusBadgeVariants
> = {
  EMPTY: 'EMPTY',
  OCCUPIED: 'OCCUPIED',
  USABLE: StorageUnitStatus.Usable,
  BLOCKED: StorageUnitStatus.Blocked,
  BROKEN: StorageUnitStatus.Broken,
};

type StatusBadgeProps = {
  title: StatusBadgeVariants | string;
  testId?: string;
};

const StatusBadge = ({ title, testId }: StatusBadgeProps) => {
  const { t } = useTranslation();

  // TODO: Handle this better way and delete this function
  function getStatus(title: string) {
    const getOccupied = /\bOccupied\b/g; // The \b matches word boundaries to ensure an exact match
    const getEmpty = /\bEmpty\b/g; // The \b matches word boundaries to ensure an exact match

    const matchesOccupied = title.match(getOccupied);
    const matchestEmpty = title.match(getEmpty);

    if (matchesOccupied) {
      return matchesOccupied[0].toUpperCase();
    } else if (matchestEmpty) {
      return matchestEmpty[0].toUpperCase();
    } else {
      return title;
    }
  }

  return (
    <Badge data-test={testId} variant={getStatus(title)}>
      {t(title)}
    </Badge>
  );
};

const Badge = styled.span<{ variant: Variant }>`
  display: inline-block;
  padding: 1rem 2rem;
  border-radius: 1rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
  ${({ variant, theme }) => {
    switch (variant) {
      case StorageUnitFilterOptionsEnum.EMPTY:
        return css`
          color: ${theme.colors.white};
          background: ${theme.colors.additional.success};
        `;
      case StorageUnitFilterOptionsEnum.OCCUPIED:
        return css`
          color: ${theme.colors.white};
          background: ${theme.colors.additional.error};
        `;
      case StorageUnitFilterOptionsEnum.USABLE:
        return css`
          color: ${theme.colors.white};
          background: ${theme.colors.additional.success};
        `;
      case StorageUnitFilterOptionsEnum.BLOCKED:
        return css`
          color: ${theme.colors.white};
          background: ${theme.colors.additional.error};
        `;
      case StorageUnitFilterOptionsEnum.BROKEN:
        return css`
          color: ${theme.colors.white};
          background: ${theme.colors.additional.error};
        `;
    }
  }}
`;

export default StatusBadge;
