import { ReactNode } from 'react';

type ConditionalWrapperProps = {
  children: ReactNode;
  condition: boolean;
  wrap: (children: ReactNode) => ReactNode;
};

const ConditionalWrapper = ({
  children,
  condition,
  wrap,
}: ConditionalWrapperProps) => {
  return <>{condition ? wrap(children) : children}</>;
};

export default ConditionalWrapper;
