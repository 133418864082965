import { getGraphQLRequestName } from '@conteg/logging';
import { GqlError } from '@conteg/ui';
import uniq from 'lodash/uniq';
import env from 'utils/env/env';
import { getToken } from 'utils/get-token/get-token';
import { logger } from 'utils/logging/init';

export function fetcher<TData, TVariables>(
  query: string,
  variables?: TVariables
) {
  return async (): Promise<TData> => {
    const variableString = JSON.stringify(variables);
    const graphQLRequestName = getGraphQLRequestName(query.trim());

    await logger.info(
      `Calling ${graphQLRequestName} with variables: ${variableString}`
    );

    const res = await fetch(env.VITE_GQL_API_URL, {
      method: 'POST',
      ...{
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': window.location.origin,
          'Access-Control-Request-Headers': 'X-Requested-With',
          'Access-Control-Request-Method': 'POST',
          Authorization: `Bearer ${getToken()}`,
        },
      },
      body: JSON.stringify({ query, variables }),
    });

    const json = await res.json();
    if (json.errors) {
      const messages = json.errors.map((error: Error) => {
        if ('message' in error) {
          return error.message;
        }
        return `Unexpected error ${JSON.stringify(error)}`;
      });

      //Use uniq to remove duplicate error messages
      const formattedErrors = uniq(messages).join('\n');

      throw new GqlError(
        variables,
        getGraphQLRequestName(query),
        json.errors,
        formattedErrors,
        res.status
      );
    }
    return json.data;
  };
}
