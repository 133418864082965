import { HiddenKioskBottomButtons, Icon, styled, Text } from '@conteg/ui';
import { useTranslation } from 'react-i18next';
import env from 'utils/env/env';

type ModalLockProps = {
  onUnlock: () => void;
};

export const ModalLock = ({ onUnlock }: ModalLockProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Icon name="lock" color="white" height="30rem" width="30rem" />
      <Text content={t('Kiosk.IdleModalInfo')} />
      <HiddenKioskBottomButtons
        onUnlock={onUnlock}
        unlockCode={env.VITE_UNLOCK_SCREEN_CODE}
      />
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  color: ${({ theme }) => theme.colors.white};
  flex-direction: column;
  gap: 3rem;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(120, 120, 120, 0.3);
  backdrop-filter: blur(6px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  p {
    color: ${({ theme }) => theme.colors.white};
  }
`;
