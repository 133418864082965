import 'utils/localization/i18n';
import 'utils/timer-manager/timer-manager-init';

import { IS_KIOSK } from 'config';
import { useIsAuthenticated } from 'utils/device-auth/device-auth-store';
import { useSubscribeStorageUnitEvents } from 'utils/hooks/storage-units-hooks';
import { useDetectDevice } from 'utils/hooks/use-detect-device';
import KioskIdleModal from 'utils/kiosk-idle-modal/kiosk-idle-modal';
import Router from 'utils/routing/router';

const App = () => {
  useDetectDevice();
  const isAuthenticated = useIsAuthenticated();

  useSubscribeStorageUnitEvents();

  return (
    <>
      <Router />
      {IS_KIOSK && isAuthenticated && <KioskIdleModal />}
    </>
  );
};

export default App;
