import { useQueryClient } from '@tanstack/react-query';
import {
  LockStatus,
  StorageUnitsQuery,
  StorageUnitStatus,
} from 'types/generated/graphql';
import { usePoint } from 'utils/device/device-store';

export const useStorageUnitsQueryKey = () => {
  const { pointId } = usePoint();

  if (pointId) {
    return [
      'StorageUnits',
      {
        pointId: pointId ?? null,
      },
    ];
  }
  return null;
};

export const useStorageUnitsCache = () => {
  const queryKey = useStorageUnitsQueryKey();
  const queryClient = useQueryClient();

  if (queryKey) {
    return queryClient.getQueryData<StorageUnitsQuery>(queryKey);
  }

  return null;
};

export const useOnLockChangeHandler = () => {
  const queryClient = useQueryClient();
  const queryKey = useStorageUnitsQueryKey();
  const storageUnits = useStorageUnitsCache();

  if (storageUnits?.storageUnits.length && queryKey) {
    const onLockChange = (storageUnitId: string, lockStatus: LockStatus) => {
      storageUnits.storageUnits = storageUnits.storageUnits.map((unit) => {
        if (unit?.storageUnit?.id === storageUnitId && unit.storageUnit.lock) {
          unit.storageUnit.lock.lastStatus = lockStatus;
        }
        return unit;
      });

      queryClient.setQueryData(queryKey, storageUnits);
    };

    return onLockChange;
  }

  return null;
};

export const useOnStatusChangeHandler = () => {
  const queryClient = useQueryClient();
  const queryKey = useStorageUnitsQueryKey();
  const storageUnits = useStorageUnitsCache();

  if (storageUnits?.storageUnits.length && queryKey) {
    return (storageUnitId: string, newState: StorageUnitStatus) => {
      storageUnits.storageUnits = storageUnits.storageUnits.map((unit) => {
        if (unit?.storageUnit?.id === storageUnitId) {
          unit.storageUnit.storageUnitStatus = newState;
        }
        return unit;
      });

      queryClient.setQueryData(queryKey, storageUnits);
    };
  }
  return null;
};
