import { Button, Flex, Heading } from '@conteg/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from 'utils/routing/routes';

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Flex
      flexDirection="column"
      gap="6rem"
      alignItems="center"
      textAlign="center"
    >
      <Heading variant="h1" title={t('Page.NotFound.Heading')} />
      <Heading variant="h3" title={t('Page.NotFound.Subheading')} />
      <Button
        title={t('Page.NotFound.BackToStorageUnits')}
        onClick={() => navigate(appRoutes.home)}
      />
    </Flex>
  );
};

export default NotFound;
