import { IS_KIOSK } from 'config';
import { Outlet } from 'react-router-dom';
import LayoutKiosk from 'utils/layout/layout-kiosk';
import LayoutWeb from 'utils/layout/layout-web';

const Layout: React.FC = () => {
  if (IS_KIOSK) {
    return (
      <LayoutKiosk>
        <Outlet />
      </LayoutKiosk>
    );
  }

  return (
    <LayoutWeb>
      <Outlet />
    </LayoutWeb>
  );
};

export default Layout;
