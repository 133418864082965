import { Box, Button, Flex, Heading, styled, Tabs } from '@conteg/ui';
import { downloadImage } from 'components/create-delivery-reservation/delivery-reservation-pin/utils';
import domToImage from 'dom-to-image';
import { QRCodeSVG } from 'qrcode.react';
import { useRef } from 'react';
import Barcode from 'react-barcode';
import { useTranslation } from 'react-i18next';

type DeliveryReservationPinProps = {
  pinCode: string;
  trackingIdentifier: string;
};

export const DeliveryReservationPin = ({
  pinCode,
  trackingIdentifier,
}: DeliveryReservationPinProps) => {
  const { t } = useTranslation();

  const codeRef = useRef(null);

  const handleDownloadImage = () => {
    if (codeRef.current) {
      domToImage.toPng(codeRef.current as HTMLElement).then(downloadImage);
    }
  };

  return (
    <>
      <Box marginBottom="4rem">
        <Heading
          variant="h1"
          title={t('CreateDeliveryReservation.PackageInfo')}
        />
      </Box>
      <Flex flexDirection="column" justifyContent="center">
        <Heading
          variant="h2"
          title={t('CreateDeliveryReservation.PinForPickup')}
        />
        <HighlightText>{pinCode}</HighlightText>
        <Heading
          variant="h2"
          title={t('CreateDeliveryReservation.PackageLabel')}
        />
        <Flex
          padding="6rem"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Tabs
            tabs={[
              {
                id: 'qr',
                title: t('CreateDeliveryReservation.QR'),
                content: (
                  <QRCodeContainer id="code" ref={codeRef}>
                    <QRCodeSVG
                      value={trackingIdentifier}
                      level="H"
                      size={300}
                      id="code"
                    />
                    <HighlightText>{trackingIdentifier}</HighlightText>
                  </QRCodeContainer>
                ),
              },
              {
                id: 'ean',
                title: t('CreateDeliveryReservation.EAN'),
                content: (
                  <Box id="code" ref={codeRef} padding="3rem">
                    <Barcode value={trackingIdentifier} />
                  </Box>
                ),
              },
            ]}
          />
        </Flex>
        <Button
          isFullWidth
          title={t('CreateDeliveryReservation.SaveCode')}
          onClick={handleDownloadImage}
        />
      </Flex>
    </>
  );
};

const HighlightText = styled.p`
  font-weight: bold;
  font-size: 6rem;
  width: 100%;
  text-align: center;
`;

const QRCodeContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 3rem;
`;
