import { AuthConfig, AuthProvider as ContegAuthProvider } from '@conteg/auth';
import { AUTH_CLIENT_ID } from 'config';
import { ReactNode } from 'react';
import env from 'utils/env/env';

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const authConfig: AuthConfig = {
    scope: 'Api.access',
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    subjectId: env.VITE_AUTH_SUBJECT_ID ?? '',
    applicationInstanceId: env.VITE_AUTH_APP_INSTANCE_ID ?? '',
    authority: env.VITE_IDENTITY_API_URL,
    clientId: AUTH_CLIENT_ID,
    issuer: 'Conteg_X2d',
    endSessionEndpoint: `${env.VITE_IDENTITY_WEB_URL}/logout`,
  };

  return (
    // TODO: Remove ts-ignore when auth will be required
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Unreachable code error
    <ContegAuthProvider config={authConfig}>{children}</ContegAuthProvider>
  );
};
