import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDeviceStore, usePoint } from 'utils/device/device-store';

export const useDetectDevice = () => {
  const setPointId = useDeviceStore.getState().setPointId;

  const { pointId: statePointId } = usePoint();

  const [searchParams, setSearchParams] = useSearchParams();

  const getInitialParam = (name: string) => {
    return searchParams.get(name);
  };

  const pointId = getInitialParam('pointId') || statePointId;
  const paramPointId = getInitialParam('pointId');
  const code = getInitialParam('code');

  useEffect(() => {
    if (pointId) {
      if (!paramPointId && !code) {
        setSearchParams({ pointId });
      }

      if (!statePointId || pointId !== statePointId) {
        setPointId(pointId);
      }
    } else {
      throw new Error(
        `Application requires at least a valid Point ID. Value provided: pointId: ${pointId}.`
      );
    }
  }, [pointId, setSearchParams, paramPointId, statePointId, code, setPointId]);
};
