import { useEffect } from 'react';
import env from 'utils/env/env';
import { ModalLock } from 'utils/kiosk-idle-modal/modal-lock';
import { useIdleModalStore } from 'utils/kiosk-idle-modal/store';
import { TimerManager } from 'utils/timer-manager/timer-manager';

const KioskIdleModal = () => {
  const timerId = 'idle-modal';

  const { isModalOpen, setIsModalOpen } = useIdleModalStore();

  const modalTimerHandler = () => {
    TimerManager.startTimer(
      timerId,
      'timeout',
      env.VITE_IDLE_TIMEOUT ?? 60,
      () => {
        setIsModalOpen(true);
      }
    );
  };

  useEffect(() => {
    modalTimerHandler();

    document.addEventListener('click', modalTimerHandler);
    document.addEventListener('keypress', modalTimerHandler);
    document.addEventListener('touchstart', modalTimerHandler);

    return () => {
      TimerManager.stopTimer(timerId, 'timeout');

      document.removeEventListener('click', modalTimerHandler);
      document.removeEventListener('keypress', modalTimerHandler);
      document.removeEventListener('touchstart', modalTimerHandler);
    };
    // This effect should only initialize and clean up the event listeners
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isModalOpen) {
    return <ModalLock onUnlock={() => setIsModalOpen(false)} />;
  }
  return null;
};

export default KioskIdleModal;
