import { Button, Flex, styled, Table, Text, Tooltip } from '@conteg/ui';
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { DebouncedSearchInput } from 'components/debounced-search-input/debounced-search-input';
import { usePolicyEvaluate } from 'components/policy-loader/policy-loader';
import StatusBadge from 'components/status-badge/status-badge';
import StorageUnitFeaturesCompact from 'components/storage-unit-features-compact/storage-unit-features-compact';
import {
  IS_KIOSK,
  PACKAGE_DETAIL_EXTERNAL_ROUTE,
  STORAGE_UNIT_HISTORY_EXTERNAL_ROUTE,
} from 'config';
import DataGridFilter, {
  StorageUnitFilterOptionsEnum,
  StorageUnitFilterValues,
} from 'pages/storage-unit-list/components/data-grid-filter';
import { sortByEmpty } from 'pages/storage-unit-list/components/sort-by-is-empty';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  LockStatus,
  StorageUnitListFragment,
  StorageUnitStatus,
} from 'types/generated/graphql';
import { useDeviceStore } from 'utils/device/device-store';
import { getStatusTranslation } from 'utils/localization/get-status-translation';
import { openExternalLink } from 'utils/open-external-link/open-external-link';
import { appRoutes, substituteRouteParams } from 'utils/routing/routes';
import { formatDateTime, TimeFormats } from 'utils/time/format-time';

type DataGridProps = {
  storageUnitList: StorageUnitListFragment[];
  isLoading: boolean;
};

const columnHelper = createColumnHelper<StorageUnitListFragment>();

const InputWrapper = styled.div`
  width: 120rem;

  input {
    height: 14rem;
  }
`;

const NeverEndingSymbol = styled(Text)`
  font-size: 5rem;
`;

const DataGrid = ({ storageUnitList, isLoading }: DataGridProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pointId = useDeviceStore((state) => state.pointId);
  const [storageUnitFilter, setStorageUnitFilter] =
    useState<StorageUnitFilterValues>(StorageUnitFilterOptionsEnum.ALL);

  const [tableData, setTableData] =
    useState<StorageUnitListFragment[]>(storageUnitList);

  const [sorting, setSorting] = useState<SortingState>([]);

  const [searchTerm, setSearchTerm] = useState<string>('');

  const canDisplayReservationDetail = usePolicyEvaluate(
    'displayReservationDetail',
    'maintenance'
  );

  const canDisplayReservationPreview = usePolicyEvaluate(
    'displayReservationPreview',
    'maintenance'
  );

  const canDisplayServiceUnitDetail = usePolicyEvaluate(
    'canDisplayServiceUnitDetail',
    'maintenance'
  );

  const canDisplayEmptyStorageUnitDetail = usePolicyEvaluate(
    'canDisplayEmptyStorageUnitDetail',
    'maintenance'
  );

  useEffect(() => {
    switch (storageUnitFilter) {
      case StorageUnitFilterOptionsEnum.ALL:
        setTableData(storageUnitList);
        break;
      case StorageUnitFilterOptionsEnum.ServiceModule:
        setTableData(
          storageUnitList.filter(
            (row) => row.storageUnit?.storageUnitSize?.isServiceModule
          )
        );
        break;
      case StorageUnitFilterOptionsEnum.EMPTY:
        setTableData(storageUnitList.filter((row) => row.isEmpty));
        break;
      case StorageUnitFilterOptionsEnum.OCCUPIED:
        setTableData(storageUnitList.filter((row) => !!row.allocationFrom));
        break;
      case LockStatus.Opened:
        setTableData(
          storageUnitList.filter(
            (row) => row.storageUnit?.lock?.lastStatus === LockStatus.Opened
          )
        );
        break;
      case StorageUnitStatus.Blocked:
        setTableData(
          storageUnitList.filter(
            (row) =>
              row.storageUnit?.storageUnitStatus === StorageUnitStatus.Blocked
          )
        );
        break;
      case StorageUnitStatus.Broken:
        setTableData(
          storageUnitList.filter(
            (row) =>
              row.storageUnit?.storageUnitStatus === StorageUnitStatus.Blocked
          )
        );
        break;
    }
  }, [storageUnitList, storageUnitFilter]);

  const storageUnitsColumns = useMemo(
    () => [
      columnHelper.accessor((row) => row.storageUnit?.name, {
        id: 'name',
        cell: (info) => info.getValue(),
        header: () => (
          <span>{t('Page.StorageUnitList.Table.Header.BoxName')}</span>
        ),
        enableSorting: true,
      }),
      columnHelper.accessor(
        (row) =>
          row.storageUnit?.storageUnitStatus === StorageUnitStatus.Blocked ? (
            <Tooltip
              testId="storage-unit-status-tooltip-blocked"
              content={t('Page.StorageUnitList.Table.Active.Blocked')}
            >
              ❌
            </Tooltip>
          ) : (
            <Tooltip
              testId="storage-unit-status-tooltip-usable"
              content={t('Page.StorageUnitList.Table.Active.Usable')}
            >
              ✅
            </Tooltip>
          ),
        {
          id: 'active',
          cell: (info) => info.getValue(),
          header: () => (
            <span>{t('Page.StorageUnitList.Table.Header.Active')}</span>
          ),
          enableSorting: false,
        }
      ),
      columnHelper.accessor(
        (row) => (
          <StorageUnitFeaturesCompact
            storageUnitFeatures={row.storageUnit.features ?? []}
          />
        ),
        {
          id: 'storageUnitFeatures',
          cell: (info) => info.getValue(),
          header: () => (
            <span>
              {t('Page.StorageUnitList.Table.Header.StorageUnitFeatures')}
            </span>
          ),
          enableSorting: false,
        }
      ),
      columnHelper.accessor(
        (row) =>
          row.storageUnit?.lock?.lastStatus === LockStatus.Closed ? (
            <Tooltip
              testId="storage-unit-locked-status-tooltip-locked"
              content={t('Page.StorageUnitList.Table.Lock.Locked')}
            >
              🔒
            </Tooltip>
          ) : (
            <Tooltip
              testId="storage-unit-locked-status-tooltip-unlocked"
              content={t('Page.StorageUnitList.Table.Lock.Unlocked')}
            >
              ❌
            </Tooltip>
          ),
        {
          id: 'lock',
          cell: (info) => info.getValue(),
          header: () => (
            <span>{t('Page.StorageUnitList.Table.Header.Lock')}</span>
          ),
          enableSorting: false,
        }
      ),
      columnHelper.accessor((row) => row, {
        id: 'status',
        cell: (info) => {
          const unit = info.getValue();

          if (unit.storageUnit.storageUnitSize?.isServiceModule) {
            return null;
          }

          return (
            <StatusBadge
              title={getStatusTranslation({ isEmpty: unit.isEmpty })}
            />
          );
        },
        header: () => (
          <span>{t('Page.StorageUnitList.Table.Header.Status')}</span>
        ),
        enableSorting: true,
        sortingFn: sortByEmpty,
        sortUndefined: false,
      }),
      columnHelper.accessor(
        (row) => {
          if (row.isNeverEndingReservation) {
            return <NeverEndingSymbol content="∞" variant="normal" />;
          }
          if (
            row.allocationFrom &&
            row.allocationTo &&
            canDisplayReservationPreview
          ) {
            return `${formatDateTime(
              row.allocationFrom,
              TimeFormats.dateTime
            )} - ${formatDateTime(row.allocationTo, TimeFormats.dateTime)}`;
          }
          return '';
        },
        {
          id: 'reservationPeriod',
          cell: (info) => info.getValue(),
          header: () => (
            <span>
              {t('Page.StorageUnitList.Table.Header.ReservationPeriod')}
            </span>
          ),
          enableSorting: false,
          sortingFn: 'datetime',
        }
      ),
      columnHelper.accessor(
        (row) =>
          canDisplayReservationPreview && (
            <StorageUnitFeaturesCompact
              storageUnitFeatures={row.requiredStorageUnitFeatures}
            />
          ),
        {
          id: 'reservationFeatures',
          cell: (info) => info.getValue(),
          header: () => (
            <span>
              {t(
                'Page.StorageUnitList.Table.Header.RequiredStorageUnitFeatures'
              )}
            </span>
          ),
          enableSorting: false,
        }
      ),
      columnHelper.accessor(
        (row) =>
          canDisplayReservationPreview && row.phoneNumber
            ? row.phoneNumber
            : '',
        {
          id: 'phoneNumber',
          cell: (info) => info.getValue(),
          header: () => (
            <span>{t('Page.StorageUnitList.Table.Header.PhoneNumber')}</span>
          ),
          enableSorting: false,
        }
      ),
      columnHelper.accessor(
        (row) => (canDisplayReservationPreview ? row.trackingIdentifier : ''),
        {
          id: 'barcode',
          cell: (info) => info.getValue(),
          header: () => (
            <span>
              {t('Page.StorageUnitList.Table.Header.ReservationNumber')}
            </span>
          ),
          enableSorting: false,
        }
      ),
      columnHelper.accessor(
        (row) => {
          const storageUnitName = row.storageUnit?.name;

          const isServiceModule =
            row.storageUnit.storageUnitSize?.isServiceModule;

          const isForbiddenServiceUnit =
            isServiceModule && !canDisplayServiceUnitDetail;

          const isForbiddenReservationDetail =
            !!row.allocationFrom && !canDisplayReservationDetail;

          const isForbiddenToOpenEmptyStorageUnitDetail =
            !row.allocationFrom &&
            !canDisplayEmptyStorageUnitDetail &&
            !isServiceModule;

          const isOccupiedByDifferentTenant =
            !row.allocationFrom && !row.isEmpty;

          const isDetailButtonDisabled =
            isForbiddenReservationDetail ||
            isForbiddenServiceUnit ||
            isForbiddenToOpenEmptyStorageUnitDetail ||
            isOccupiedByDifferentTenant;

          const isStorageUnitHistoryButtonDisabled =
            isForbiddenReservationDetail ||
            isForbiddenServiceUnit ||
            isForbiddenToOpenEmptyStorageUnitDetail;

          return (
            <Flex gap="3rem">
              <Tooltip
                testId="storage-unit-detail-button-tooltip"
                content={t(
                  'Page.StorageUnitList.Table.Detail.BlockedDetailButtonTooltip'
                )}
                isTooltipHidden={!isDetailButtonDisabled}
              >
                <Button
                  disabled={isDetailButtonDisabled}
                  testId={row.storageUnit?.id}
                  title={t('Page.StorageUnitList.Table.Button.Details')}
                  variant="primary"
                  onClick={() =>
                    navigate(
                      substituteRouteParams(appRoutes.storageUnitDetail, {
                        storageUnitId: row.storageUnit?.id,
                      })
                    )
                  }
                />
              </Tooltip>
              {storageUnitName && pointId && !IS_KIOSK && (
                <Button
                  disabled={isStorageUnitHistoryButtonDisabled}
                  title={t(
                    'Page.StorageUnitList.Table.Button.StorageUnitHistory'
                  )}
                  variant="primary"
                  onClick={() =>
                    openExternalLink(
                      substituteRouteParams(
                        STORAGE_UNIT_HISTORY_EXTERNAL_ROUTE,
                        {
                          storageUnitName,
                          pointId,
                        }
                      )
                    )
                  }
                />
              )}
              {row.allocationFrom &&
                row.trackingIdentifier &&
                canDisplayReservationDetail &&
                !IS_KIOSK && (
                  <Button
                    disabled={isDetailButtonDisabled}
                    testId="storage-unit-history-button"
                    title={t(
                      'Page.StorageUnitList.Table.Button.PackageHistory'
                    )}
                    variant="primary"
                    onClick={() =>
                      openExternalLink(
                        substituteRouteParams(PACKAGE_DETAIL_EXTERNAL_ROUTE, {
                          trackingIdentifier: row.trackingIdentifier as string,
                        })
                      )
                    }
                  />
                )}
            </Flex>
          );
        },
        {
          id: 'actions',
          cell: (info) => info.getValue(),
          header: () => (
            <span>{t('Page.StorageUnitList.Table.Header.Actions')}</span>
          ),
          enableSorting: false,
        }
      ),
    ],
    // We don't want add navigate to dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      t,
      canDisplayReservationPreview,
      canDisplayServiceUnitDetail,
      canDisplayReservationDetail,
      canDisplayEmptyStorageUnitDetail,
      pointId,
    ]
  );

  const filteredData = useMemo(
    () =>
      searchTerm
        ? tableData.filter((item) => filterDataBySearchTerm(item, searchTerm))
        : tableData,
    [searchTerm, tableData]
  );

  const storageUnitsTable = useReactTable<StorageUnitListFragment>({
    data: filteredData ?? [],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      columnVisibility: { nextReservation: !IS_KIOSK },
      sorting,
    },
    columns: storageUnitsColumns,
  });

  return (
    <Flex flexDirection="column" gap="1rem">
      <Flex gap="5rem" alignItems="center">
        <InputWrapper>
          <DebouncedSearchInput
            label={t('Page.StorageUnitList.Filter.Input.Label')}
            onChange={setSearchTerm}
          />
        </InputWrapper>
        <DataGridFilter
          selectedStatus={storageUnitFilter}
          setStatus={setStorageUnitFilter}
        />
      </Flex>
      <Table
        testId="storage-unit-list-table"
        table={storageUnitsTable}
        isEmpty={!tableData.length}
        isLoading={isLoading}
        emptyState={{
          title: t('Page.StorageUnitList.Table.EmptyData.Title'),
          description: t('Page.StorageUnitList.Table.EmptyData.Description'),
        }}
        disableRow={(row) =>
          row.storageUnit?.storageUnitStatus === StorageUnitStatus.Blocked
        }
      />
    </Flex>
  );
};

export default DataGrid;

const filterDataBySearchTerm = (
  row: StorageUnitListFragment,
  searchTerm: string
) => {
  if (!searchTerm) {
    return true;
  }

  const formattedSearchTerm = searchTerm.toLowerCase().replace(' ', '');

  const formattedRow = `${row.trackingIdentifier}${row.phoneNumber}`
    .replace(' ', '')
    .toLowerCase();

  return formattedRow.includes(formattedSearchTerm);
};
