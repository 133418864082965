import { ThemeProvider } from '@conteg/ui';
import App from 'app';
import ErrorFallback from 'components/error-fallback/error-fallback';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'utils/auth/auth';
import { GqlSubscriptionProvider } from 'utils/gql/gql-subscriptions';
import QueryClientProvider from 'utils/gql/query-client-provider';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ThemeProvider>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AuthProvider>
        <QueryClientProvider>
          <GqlSubscriptionProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </GqlSubscriptionProvider>
        </QueryClientProvider>
      </AuthProvider>
    </ErrorBoundary>
  </ThemeProvider>
);
