import { AUTH_CLIENT_ID, CUSTOM_AUTH_TOKEN, IS_KIOSK } from 'config';
import { useDeviceAuthStore } from 'utils/device-auth/device-auth-store';
import env from 'utils/env/env';
import { logger } from 'utils/logging/init';

export const getToken = () => {
  if (CUSTOM_AUTH_TOKEN) {
    return CUSTOM_AUTH_TOKEN;
  }

  if (IS_KIOSK) {
    logger.info('Using device auth token');
    return useDeviceAuthStore.getState().token;
  }

  if (typeof window !== 'undefined' && window.localStorage) {
    try {
      const localStorageRecordAsString = localStorage.getItem(
        `oidc.user:${env.VITE_IDENTITY_API_URL}:${AUTH_CLIENT_ID}`
      );

      if (!localStorageRecordAsString) {
        return null;
      }

      try {
        const record = JSON.parse(localStorageRecordAsString);
        return record?.access_token;
      } catch (error: unknown) {
        logger.error(
          `Error parsing localStorage record: ${JSON.stringify(error)}`
        );
        return null;
      }
    } catch (error: unknown) {
      logger.error(`Error accessing localStorage: ${JSON.stringify(error)}`);
      return null;
    }
  } else {
    logger.warn('localStorage is not available');
    return null;
  }
};
