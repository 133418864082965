import { DateTime } from 'luxon';

export enum TimeFormats {
  date = 'd. M. yyyy',
  dateTime = 'd. M. yyyy HH:mm',
}

export const formatDateTime = (dateTime: string, format: string) => {
  return DateTime.fromISO(dateTime).toFormat(format);
};
