import {
  Button,
  ConfirmModal,
  ErrorDetailInfo,
  Flex,
  Heading,
  Loading,
  showAlertModalError,
  styled,
  Text,
} from '@conteg/ui';
import Breadcrumbs from 'components/breadcrumbs/breadcrumbs';
import Error from 'components/error/error';
import Notification from 'components/notification/notification';
import { PolicyGuardWrapper } from 'components/policy-guard/policy-guard';
import StatusBadge from 'components/status-badge/status-badge';
import StorageUnitFeaturesDetail from 'components/storage-unit-features-detail/storage-unit-features-detail';
import AllocationActions from 'pages/storage-unit-detail/components/actions/allocation-actions';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  LockStatus,
  useAppConfigQuery,
  useOpenEmptyStorageUnitMutation,
  useOpenServiceModuleMutation,
  useStorageUnitQuery,
} from 'types/generated/graphql';
import { usePoint } from 'utils/device/device-store';
import { appRoutes } from 'utils/routing/routes';

import CreateBlockingReservation from './components/actions/create-blocking-reservation/create-blocking-reservation';
import CreateNeverEndingReservation from './components/actions/create-never-ending-reservation/create-never-ending-reservation';
import AllocationDetail from './components/allocation-detail/allocation-detail';

const PointAddress = styled.div`
  font-size: 4rem;
  font-weight: 500;
  margin: 0 2rem;
`;

const search = window.location.search;

const StorageUnitDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showModalConfirmation, setShowModalConfirmation] =
    useState<boolean>(false);
  const [formNotice, setFormNotice] = useState<string>('');

  const { storageUnitId } = useParams<{ storageUnitId?: string }>();
  const { pointId } = usePoint();

  const {
    data,
    isLoading,
    error: storageUnitError,
  } = useStorageUnitQuery(
    {
      pointId: pointId as string,
      storageUnitId: storageUnitId as string,
    },
    {
      enabled: !!pointId && !!storageUnitId,
      select: (data) => data.storageUnit,
    }
  );

  const { data: appConfigData, error: appConfigError } = useAppConfigQuery(
    {
      pointId: pointId as string,
    },
    {
      enabled: !!pointId,
    }
  );

  const { mutateAsync: openServiceModuleMutation } =
    useOpenServiceModuleMutation();
  const { mutateAsync: openEmptyStorageMutation } =
    useOpenEmptyStorageUnitMutation();

  const handleOpenServiceModule = async () => {
    setFormNotice('');
    await openServiceModuleMutation(
      {
        pointId: pointId as string,
        storageUnitId: storageUnitId as string,
      },
      {
        onSuccess: () => {
          setFormNotice('Page.StorageUnitDetail.ServiceModule.Open.Success');
          setShowModalConfirmation(false);
        },
        onError: (error) => {
          showAlertModalError(
            t('Page.StorageUnitDetail.ServiceModule.Open.Error'),
            error
          );
          setShowModalConfirmation(false);
        },
      }
    );
  };

  const handleOpenWithoutPin = async () => {
    setFormNotice('');
    await openEmptyStorageMutation(
      {
        pointId: pointId as string,
        storageUnitId: storageUnitId as string,
      },
      {
        onSuccess: () => {
          setFormNotice('Page.StorageUnitDetail.StorageUnit.Open.Success');
          setShowModalConfirmation(false);
        },
        onError: (error) => {
          setShowModalConfirmation(false);
          showAlertModalError(
            t('Page.StorageUnitDetail.StorageUnit.Open.Error'),
            error
          );
        },
      }
    );
  };

  if (isLoading) {
    return (
      <Flex alignItem="center" justifyContent="center">
        <Loading
          testId="storage-unit-detail-loading"
          text={t('Page.StorageUnitDetail.Loading')}
        />
      </Flex>
    );
  }

  if (appConfigError) {
    return (
      <ErrorDetailInfo
        title={t('Error.AppConfigLoadError')}
        error={appConfigError}
      />
    );
  }

  if (storageUnitError) {
    return (
      <ErrorDetailInfo
        title={t('Error.Error.LoadStorageUnitDetailError')}
        error={storageUnitError}
      />
    );
  }

  if (!data) {
    return (
      <Error
        message="Page.StorageUnitDetail.Error"
        navigate={navigate}
        showToUnitListButton
      />
    );
  }

  const { storageUnit, allocation, hasReservation } = data;

  return (
    <Flex flexDirection="column" gap="6rem">
      <Flex flexDirection="column" gap="5rem">
        {appConfigData?.appConfig?.pointFullName && (
          <PointAddress>{appConfigData?.appConfig?.pointFullName}</PointAddress>
        )}
        <Breadcrumbs />
      </Flex>
      <Flex justifyContent="space-between">
        <Heading
          testId="storage-unit-id"
          variant="h2"
          title={t('Page.StorageUnit.Heading', {
            name: storageUnit?.name,
          })}
        />
        <Button
          testId="back-to-storage-units-list-button"
          variant="primary"
          size="xs"
          title={t('Page.StorageUnit.BackToList')}
          onClick={() => navigate(`${appRoutes.home}${search}`)}
        />
      </Flex>
      {formNotice && (
        <Notification
          testId="storage-unit-detail-notification"
          message={formNotice}
        />
      )}
      <Text
        testId="storage-unit-info"
        variant="large"
        content={
          <>
            {`${t('Page.StorageUnit.Info.Status')}: `}
            <StatusBadge title={storageUnit.storageUnitStatus} />
            {`, ${t('Page.StorageUnit.Info.LockStatus')}: `}
            <strong>
              {t(
                `Page.StorageUnit.Info.LockStatus.${
                  storageUnit?.lock?.lastStatus === LockStatus.Closed
                    ? 'Locked'
                    : 'Unlocked'
                }`
              )}
            </strong>
            {`, ${t('Page.StorageUnit.Info.ReservationsCount')}: `}
            <strong>{hasReservation ? 1 : 0}</strong>
          </>
        }
      />
      <StorageUnitFeaturesDetail
        onReservation={false}
        storageUnitFeatures={data.storageUnit.features}
      />
      {/* TODO: uncomment when maintenance will be ready */}
      {/* {isMaintenanceRequested && (
        <Text
          testId="storage-unit-maintenance-requested"
          variant="highlight"
          content={t('Page.StorageUnit.Info.MaintenanceRequested')}
        />
      )} */}
      {storageUnit.storageUnitSize?.isServiceModule ? (
        <>
          <PolicyGuardWrapper action="canOpenServiceUnit">
            <Button
              variant="danger"
              size="l"
              title={t('Page.StorageUnitDetail.OpenServiceModule')}
              onClick={() => setShowModalConfirmation(true)}
            />
          </PolicyGuardWrapper>
          <ConfirmModal
            zIndexOffset={101}
            modalTitle={t('Opravdu chcete otevřít servisní modul?')}
            isOpen={showModalConfirmation}
            confirm={{
              text: t('Page.StorageUnitDetail.OpenServiceModule'),
              variant: 'danger',
            }}
            cancelText={t('Page.StorageUnit.Otp.EndReservation.CancelButton')}
            onClose={() => setShowModalConfirmation(false)}
            onConfirm={() => {
              setShowModalConfirmation(false);
              handleOpenServiceModule();
            }}
          />
        </>
      ) : (
        !allocation &&
        !hasReservation && (
          <>
            <Flex alignItems="center" gap="5rem">
              <PolicyGuardWrapper action="createBlockReservation">
                <CreateBlockingReservation />
              </PolicyGuardWrapper>
              <PolicyGuardWrapper action="createBlockReservation">
                <CreateNeverEndingReservation />
              </PolicyGuardWrapper>
              {/* Button to open modal to open empty storage unit */}
              <PolicyGuardWrapper action="openEmptyStorageUnit">
                <Button
                  testId="open-empty-storage-unit"
                  variant="primary"
                  size="l"
                  title={t('Page.StorageUnitDetail.OpenEmptyStorageUnit')}
                  onClick={() => setShowModalConfirmation(true)}
                />
              </PolicyGuardWrapper>
            </Flex>
            {/* Confirm modal to open empty storage unit */}
            <ConfirmModal
              zIndexOffset={110}
              modalTitle={t(
                'Page.StorageUnitDetail.Confirm.OpenEmptyStorageUnit'
              )}
              isOpen={showModalConfirmation}
              confirm={{
                text: t('Page.StorageUnitDetail.OpenEmptyStorageUnit'),
                variant: 'primary',
              }}
              cancelText={t('Page.StorageUnit.Otp.EndReservation.CancelButton')}
              onClose={() => setShowModalConfirmation(false)}
              onConfirm={() => {
                handleOpenWithoutPin();
                setShowModalConfirmation(false);
              }}
            />
          </>
        )
      )}
      {allocation && (
        <>
          <AllocationActions storageUnit={data} />
          <AllocationDetail storageUnit={data} />
        </>
      )}
    </Flex>
  );
};

export default StorageUnitDetail;
