import { Breadcrumb } from '@conteg/ui';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { appRoutes, substituteRouteParams } from 'utils/routing/routes';

export const useBreadcrumbs = (): Breadcrumb[] => {
  const { t } = useTranslation();
  const params = useParams<{
    storageUnitId?: string;
    reservationId?: string;
  }>();

  const breadcrumbsData: Breadcrumb[] = [
    {
      label: t('Breadcrumbs.Home'),
      url: appRoutes.home,
    },
  ];

  if (params.storageUnitId) {
    breadcrumbsData.push({
      label: t('BreadCrumbs.StorageUnit'),
      url: substituteRouteParams(appRoutes.storageUnitDetail, params),
    });
  }

  return breadcrumbsData;
};
