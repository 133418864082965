import {
  Button,
  ErrorDetailInfo,
  Flex,
  Heading,
  showAlertModalError,
} from '@conteg/ui';
import Notification from 'components/notification/notification';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ServiceTypeEnum,
  StorageUnitDocument,
  useOpenStorageUnitMutation,
  useTenantsQuery,
} from 'types/generated/graphql';
import { usePoint } from 'utils/device/device-store';
import { useInvalidateQueries } from 'utils/hooks/use-ivalidate-queries';

const OpenWithoutPin = ({ storageUnitId }: { storageUnitId: string }) => {
  const { t } = useTranslation();
  const { pointId } = usePoint();
  const invalidate = useInvalidateQueries();

  const [formNotice, setFormNotice] = useState<string>('');

  const { mutateAsync: openStorageUnitWithoutPin, isPending } =
    useOpenStorageUnitMutation();

  const { data: tenants, error: tenantsError } = useTenantsQuery(
    {
      pointId: pointId as string,
    },
    {
      enabled: !!pointId,
      gcTime: 0,
      staleTime: 0,
      select: (data) => data.tenants,
    }
  );

  const handleOpenWithoutPin = async () => {
    setFormNotice('');
    await openStorageUnitWithoutPin(
      {
        pointId: pointId as string,
        storageUnitId,
        endUser: { accessPin: '' },
        isBlockingReservation: true,
        projectId: tenants?.[0]?.projectId as string,
        // TODO:FIXME: remove as, this is a workaround for the backend bug. As breaks single source of truth.
        serviceType: tenants?.[0]?.tenantServiceType as ServiceTypeEnum,
        singleTenantVerification: true,
      },
      {
        onSuccess: () => {
          setFormNotice('Page.StorageUnit.Actions.Open.WithoutPin.Success');
          invalidate([StorageUnitDocument]);
        },
        onError: (error) =>
          showAlertModalError(
            t('Page.StorageUnit.Actions.Open.WithoutPin.Error'),
            error
          ),
      }
    );
  };

  if (tenantsError) {
    <ErrorDetailInfo
      error={tenantsError}
      title={t('Error.TenantsLoadError')}
    />;
  }

  return (
    <Flex flexDirection="column" alignItems="center" gap="6rem" mt="5rem">
      <Heading
        variant="h2"
        title={t('Page.StorageUnit.Actions.Open.WithoutPin')}
      />
      {formNotice && (
        <Notification testId="resend-sms-notification" message={formNotice} />
      )}
      <Button
        title={t('Page.StorageUnit.Actions.Open.WithoutPin.Button')}
        testId="open-without-pin-button"
        onClick={handleOpenWithoutPin}
        variant="primary"
        size="xl"
        disabled={isPending}
      />
    </Flex>
  );
};

export default OpenWithoutPin;
