import { usePolicyEvaluate } from 'components/policy-loader/policy-loader';
import { ReactNode } from 'react';
import { PolicyAction } from 'types/policies-types';

type PolicyGuardProps = {
  action: PolicyAction;
  children: ReactNode;
  additionalCondition?: boolean;
};

export const PolicyGuardWrapper = ({
  action,
  children,
  additionalCondition,
}: PolicyGuardProps) => {
  const passes = usePolicyEvaluate(action, 'maintenance');

  if (additionalCondition === false) {
    return null;
  }

  if (!passes) {
    return null;
  }

  return <>{children}</>;
};
