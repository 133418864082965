import { useEffect } from 'react';
import {
  LockStatus,
  StorageUnitStateChangedDocument,
  StorageUnitSubscriptionDto,
  Subscription,
  SubscriptionStorageUnitStateChangedArgs,
} from 'types/generated/graphql';
import { useSubscription } from 'urql';
import { usePoint } from 'utils/device/device-store';
import { create } from 'zustand';

import { useOnLockChangeHandler } from './storage-units-event-handlers';

export type StorageUnitsEvent = {
  storageUnitId: string;
  label: string;
  state: LockStatus;
};

type StorageUnitsEventStore = {
  events: StorageUnitsEvent[];
  actions: {
    addEvent: (event: StorageUnitsEvent) => void;
    removeEvent: (storageUnitId: string) => void;
  };
};

function addEvent(events: StorageUnitsEvent[], event: StorageUnitsEvent) {
  const storageUnit = events.find(
    (e) => e.storageUnitId === event.storageUnitId
  );

  let newEvents = [...events];
  if (storageUnit) {
    newEvents = [
      ...newEvents.filter((e) => e.storageUnitId !== event.storageUnitId),
      { ...storageUnit, state: event.state },
    ];
  } else {
    newEvents = [...newEvents, event];
  }

  return newEvents;
}

function removeEvent(
  events: StorageUnitsEvent[],
  storageUnitIdToRemove: string
) {
  return events.filter((e) => e.storageUnitId !== storageUnitIdToRemove);
}

const useStorageUnitsEvents = create<StorageUnitsEventStore>((set) => ({
  events: [],
  actions: {
    addEvent: (event) =>
      set((state) => ({ events: addEvent(state.events, event) })),
    removeEvent: (storageUnitId) =>
      set((state) => ({
        events: removeEvent(state.events, storageUnitId),
      })),
  },
}));

export const useStorageUnitEvents = () =>
  useStorageUnitsEvents((state) => state.events);
export const useStorageUnitEvent = (storageUnitId: string | null) =>
  useStorageUnitEvents().find((event) => event.storageUnitId === storageUnitId);
export const useStorageUnitEventsActions = () =>
  useStorageUnitsEvents((state) => state.actions);

export const useSubscribeStorageUnitEvents = () => {
  const onLockChange = useOnLockChangeHandler();
  const { pointId } = usePoint();
  const { addEvent, removeEvent } = useStorageUnitEventsActions();

  const [result] = useSubscription<
    Subscription,
    StorageUnitSubscriptionDto | null | undefined,
    SubscriptionStorageUnitStateChangedArgs
  >(
    {
      query: StorageUnitStateChangedDocument,
      variables: {
        pointId: pointId ?? '',
      },
      pause: !pointId,
    },
    (_, data) => data.storageUnitStateChanged
  );

  useEffect(() => {
    if (!result?.data?.state) return;

    if (result.data.state === LockStatus.Opened) {
      addEvent({
        storageUnitId: result.data.id,
        label: result.data.label,
        state: result.data.state,
      });
      if (pointId && onLockChange)
        onLockChange(result.data.id, LockStatus.Opened);
    } else if (result.data.state === LockStatus.Closed) {
      removeEvent(result.data.id);
      if (pointId && onLockChange)
        onLockChange(result.data.id, LockStatus.Closed);
    } else {
      throw new Error(`Unknown storage unit state: ${result.data.state}`);
    }
  }, [addEvent, pointId, removeEvent, result, result.data, onLockChange]);
};
