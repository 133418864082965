import { styled } from '@conteg/ui';

export const SelectWrapper = styled.div`
  > div {
    background: ${({ theme }) => theme.colors.white};
    border-radius: 2.5rem;
  }
`;

export const InputWrapper = styled.div`
  input {
    background: ${({ theme }) => theme.colors.white};
  }
`;
