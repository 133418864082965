import { StorageUnitListFragment } from 'types/generated/graphql';

export const sortStorageUnitByName = (
  a?: StorageUnitListFragment | null,
  b?: StorageUnitListFragment | null
) => {
  return getNumberFromStorageUnitName(a) - getNumberFromStorageUnitName(b);
};

export const isServiceModule = (
  unit: StorageUnitListFragment | null | undefined
) => unit?.storageUnit?.storageUnitSize?.isServiceModule;

const getNumberFromStorageUnitName = (a?: StorageUnitListFragment | null) => {
  if (isServiceModule(a)) {
    return 1000;
  }
  return parseInt(a?.storageUnit?.name ?? '');
};
