import { Button, Card, Flex, Heading, Loading, styled, Text } from '@conteg/ui';
import Notification from 'components/notification/notification';
import { QRCodeSVG } from 'qrcode.react';
import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  useActions,
  useError,
  useIsAuthenticated,
  useLoginData,
  useStatus,
} from 'utils/device-auth/device-auth-store';
import env from 'utils/env/env';
import { dispatchLeavePage } from 'utils/post-messages/dispatch-leave-page';
import { appRoutes } from 'utils/routing/routes';
import CountdownTimer from 'utils/timer-manager/countdown-timer';
import { TimerManager } from 'utils/timer-manager/timer-manager';

// TODO: check if there is way to get rid of !important to override layout styles
const StyledWrapper = styled.div`
  h1,
  h3,
  h4,
  p {
    color: ${({ theme }) => theme.colors.black}!important;
  }
`;

const Wrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Card mx="auto">
      <StyledWrapper>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap="4rem"
        >
          {children}
        </Flex>
      </StyledWrapper>
    </Card>
  );
};

const search = window.location.search;

const LoginKiosk = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const loginStatus = useStatus();
  const error = useError();
  const isAuthenticated = useIsAuthenticated();
  const loginData = useLoginData();
  const { loginInit, loginPolling } = useActions();

  const countdownId = 'login-countdown';

  useEffect(() => {
    if (isAuthenticated) {
      TimerManager.stopTimer(countdownId, 'interval');
      navigate(`${appRoutes.home}${search}`);
    }
  }, [isAuthenticated, navigate]);

  // Init login or start polling
  useEffect(() => {
    if (
      loginData === null &&
      loginStatus !== 'error' &&
      loginStatus !== 'loading'
    ) {
      loginInit();
    }
    if (loginData?.verification_uri_complete) {
      loginPolling();
    }
  }, [loginData, loginInit, loginPolling, loginStatus]);

  if (loginStatus === 'error') {
    return (
      <Wrapper>
        <Heading title={t('Page.LoginKiosk.ErrorHeading')} variant="h1" />
        <Notification testId="login" message={error} />
        <Button
          testId="login-button"
          title={t('Page.Login.LoginButton')}
          size="xl"
          onClick={() => loginInit()}
        />
      </Wrapper>
    );
  }

  if (loginStatus === 'polling' && loginData?.verification_uri_complete) {
    return (
      <Wrapper>
        <QRCodeSVG
          data-test="login-qr"
          value={loginData?.verification_uri_complete}
          level="H"
          size={256}
        />
        {env.NODE_ENV === 'development' && (
          <LoginLink
            href={loginData?.verification_uri_complete}
            target="_blank"
            rel="noreferrer"
          >
            <Heading
              title={loginData?.verification_uri_complete}
              variant="h4"
              bold="500"
            />
          </LoginLink>
        )}

        <Text
          testId="code-info"
          content={t('Page.LoginKiosk.Info')}
          variant="large"
        />
        <Text
          testId="code-expiration"
          content={t('Page.LoginKiosk.Timeout')}
          variant="highlight"
        />

        <CountdownTimer
          time={loginData.expires_in}
          countdownId={countdownId}
          onFinish={dispatchLeavePage}
        />

        <Button
          testId="login-button"
          title={t('Page.LoginKiosk.NewCode')}
          size="xs"
          onClick={() => loginInit(true)}
        />
      </Wrapper>
    );
  }

  return (
    <Card mx="auto">
      <Loading testId="loading" text={t('Page.Login.Loading')} />
    </Card>
  );
};

const LoginLink = styled.a`
  color: ${({ theme }) => theme.colors.primary.blue400};
`;

export default LoginKiosk;
