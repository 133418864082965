import { createClient as createWSClient, SubscribePayload } from 'graphql-ws';
import { useEffect } from 'react';
import {
  cacheExchange,
  createClient,
  fetchExchange,
  Provider,
  subscriptionExchange,
} from 'urql';
import { usePoint } from 'utils/device/device-store';
import env from 'utils/env/env';
import { logger } from 'utils/logging/init';
import { create } from 'zustand';

const wsClient = createWSClient({
  url: env.VITE_GQL_API_URL.replace('http', 'ws'),
  lazy: false,
  retryAttempts: Infinity,
  shouldRetry: () => true,
  keepAlive: 5_000,
});

const client = createClient({
  url: env.VITE_GQL_API_URL,
  exchanges: [
    cacheExchange,
    fetchExchange,
    subscriptionExchange({
      forwardSubscription(operation) {
        return {
          subscribe: (sink) => {
            const dispose = wsClient.subscribe(
              operation as SubscribePayload,
              sink
            );
            return {
              unsubscribe: dispose,
            };
          },
        };
      },
    }),
  ],
});

type ConnectionState = 'closed' | 'connected';
type ConnectionStatus = {
  state: ConnectionState;
  changeState: (state: ConnectionState) => void;
};

const useConnectionStatus = create<ConnectionStatus>((set) => ({
  state: 'closed',
  changeState: (state) => set({ state }),
}));

export const useConnectionState = () =>
  useConnectionStatus((state) => state.state);
const useChangeConnectionState = () =>
  useConnectionStatus((state) => state.changeState);

export const GqlSubscriptionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const changeConnectionState = useChangeConnectionState();
  const { pointId } = usePoint();

  useEffect(() => {
    const unsubscribeMessage = wsClient.on('message', (message) => {
      if (message.type === 'ping' || message.type === 'pong') return;
      logger.info(
        `Subscription client at point: ${pointId} with message: ${JSON.stringify(
          message
        )}`
      );
    });

    const unsubscribeError = wsClient.on('error', (error) => {
      logger.error(
        `Subscription client at point: ${pointId} with  error: ${JSON.stringify(
          error
        )}`
      );
    });

    return () => {
      unsubscribeMessage();
      unsubscribeError();
    };
  }, [pointId]);

  useEffect(() => {
    const unsubscribeConnected = wsClient.on('connected', () => {
      logger.info(`Subscription client connected at point ${pointId}.`);
      changeConnectionState('connected');
    });

    const unsubscribeClosed = wsClient.on('closed', () => {
      logger.info(`Subscription client closed connection at point ${pointId}.`);
      changeConnectionState('closed');
    });

    return () => {
      unsubscribeConnected();
      unsubscribeClosed();
    };
  }, [changeConnectionState, pointId]);

  return <Provider value={client}>{children}</Provider>;
};
