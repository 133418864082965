import { IFrameMessageType } from '@conteg/ui';

import { sendPostMessage } from './send-post-message';

export const dispatchLeavePage = () =>
  sendPostMessage({
    type: IFrameMessageType.LEAVE,
  });

export const dispatchLockLauncherWeb = () =>
  sendPostMessage({
    type: IFrameMessageType.LOCK_POINT,
  });
