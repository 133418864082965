import { useQueryClient } from '@tanstack/react-query';
import { getQueryName } from 'utils/get-query-name/get-query-name';

export const useInvalidateQueries = () => {
  const queryCache = useQueryClient();

  const invalidate = (documents: string[]) => {
    documents.forEach((document) => {
      queryCache.invalidateQueries({ queryKey: [getQueryName(document)] });
    });
  };
  return invalidate;
};
