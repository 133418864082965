type RequestOptions = {
  method: string;
  cache: RequestCache | undefined;
  mode: RequestMode | undefined;
  body: string;
  headers: {
    'Content-type': 'application/x-www-form-urlencoded';
  };
};

export const objectToUrl = (data: object) => {
  return Object.keys(data)
    .map(
      (key) => `${key}=${encodeURIComponent(data[key as keyof typeof data])}`
    )
    .join('&');
};

export const createRequestOptions = (body: string): RequestOptions => {
  return {
    method: 'POST',
    cache: 'no-cache',
    mode: 'cors',
    body,
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
    },
  };
};
