import { PolicyLoader } from 'components/policy-loader/policy-loader';
import { CUSTOM_AUTH_TOKEN } from 'config';
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

type ProtectedRouteProps = {
  children: ReactNode;
  isAuthenticated: boolean;
};

const ProtectedRoute = ({ children, isAuthenticated }: ProtectedRouteProps) => {
  if (isAuthenticated || CUSTOM_AUTH_TOKEN) {
    return <PolicyLoader>{children}</PolicyLoader>;
  }

  return <Navigate to="/" />;
};

export default ProtectedRoute;
