import { Alert } from '@conteg/ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type NotificationProps = {
  message: string;
  testId?: string;
  translationParams?: Record<string, string>;
};

const Notification = ({
  message,
  testId,
  translationParams,
}: NotificationProps) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 5_000);

    return () => clearTimeout(timer);
  }, []);

  const type = message.toLowerCase().includes('error')
    ? 'error'
    : message.toLowerCase().includes('warning')
      ? 'warning'
      : 'success';

  let translation = '';
  if (translationParams) {
    translation = t(message, translationParams);
  } else {
    translation = t(message);
  }

  if (!isVisible) {
    return null;
  }

  return (
    <Alert
      testId={`${testId ?? 'notification'}-${type}`}
      type={type}
      message={translation}
    />
  );
};

export default Notification;
