import {
  StorageUnitSizeFragment,
  useStorageUnitSizesQuery,
} from 'types/generated/graphql';
import { usePoint } from 'utils/device/device-store';

const getVolume = (size: StorageUnitSizeFragment) =>
  size.innerDepth * size.innerHeight * size.innerWidth;

export const useBiggestStorageUnitSize = () => {
  const { pointId } = usePoint();

  const { data, ...rest } = useStorageUnitSizesQuery(
    { pointId: pointId as string },
    {
      select: (data) =>
        data?.storageUnitSizes?.sort((a, b) => getVolume(b) - getVolume(a))[0],
    }
  );

  return {
    biggestStorageUnitSize: data,
    ...rest,
  };
};
