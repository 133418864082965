import env from 'utils/env/env';
import { isInCypress } from 'utils/is-in-cypress/is-in-cypress';

export const CYPRESS_ACCESS_TOKEN =
  'eyJhbGciOiJSUzI1NiIsImtpZCI6IjYwNTM4REUyQ0E2MjAxRDdFNUZCRkQ5ODQ5QTFCNDhDIiwidHlwIjoiYXQrand0In0.eyJpc3MiOiJodHRwczovL2FwaS5pZGVudGl0eS5kZXYuMnhsb2NrZXJzLmV1IiwibmJmIjoxNzA1NDg1MDI4LCJpYXQiOjE3MDU0ODUwMjgsImV4cCI6MTcwNTQ4ODYyOCwiYXVkIjpbIklkZW50aXR5QXBpIiwiUmVzZXJ2YXRpb25Mb2dpc3RpY3NBcGkiLCJDb21wYW55QXBpIiwiRGV2aWNlQXBpIiwiR2F0ZXdheUFwaSIsIkF1ZGl0QXBpIiwiSGFyZHdhcmVBcGkiLCJJbnRlZ3JhdGlvbkFwaSIsIkNlc2thUG9zdGFBcGkiLCJDZXNrYVNwb3JpdGVsbmFBcGkiLCJFdmVudEJyb2tlciIsIldvcmtmbG93QXBpIiwiU3luY2hyb25pemF0aW9uQXBpIiwiQ29tbXVuaWNhdGlvbkFwaSIsIkNvbW1vbkFwaSJdLCJzY29wZSI6WyJBcGkuYWNjZXNzIiwiSW50ZWdyYXRpb25BcGkucmVhZCIsIkludGVncmF0aW9uQXBpLndyaXRlIiwib2ZmbGluZV9hY2Nlc3MiXSwiYW1yIjpbInB3ZCJdLCJjbGllbnRfaWQiOiJhbnlfbW9iX3dlYl9hcHBfaW50ZXJhY3RpdmVfY2xpZW50Iiwic3ViIjoiMjI1N2I5ZTctNjEwYy00ZmFjLTgzNjktNjRmYTBjNTMzOTFiIiwiYXV0aF90aW1lIjoxNzA1NDg1MDI3LCJpZHAiOiJsb2NhbCIsIkFwcGxpY2F0aW9uSWQiOiIiLCJFbWFpbCI6Im1pY2hhbC5rcmF1c2tvcGYudmx0cm5AZ21haWwuY29tIiwiRmlyc3ROYW1lIjoiTWljaGFsIiwiTGFzdE5hbWUiOiJLcmF1c2tvcGYiLCJJc3N1ZURhdGUiOiIwMS8xNy8yMDI0IDA5OjUwOjI4ICswMDowMCIsIlN1YmplY3RJZCI6ImRjZDNmNjJiLWQxY2UtZmEwOS1lMjY0LTMwM2YwNzdkOTUyMSIsIlVzZXJJZCI6IjIyNTdiOWU3LTYxMGMtNGZhYy04MzY5LTY0ZmEwYzUzMzkxYiIsIkFwcGxpY2F0aW9uSW5zdGFuY2VJZCI6IiIsIlVzZXJUeXBlIjoiUmVnaXN0ZXJlZCIsIklzUHJvdmlkZXIiOiJUcnVlIiwiUm9sZU5hbWVzIjpbIiIsIkFkbWluIl0sInNpZCI6IkVDRTJDOTc3MUQzNDM4RTQxQzYzQ0EzMjM5QTcxQzE3IiwianRpIjoiMzE5QjM1QTk3OUUwMzMwNUJBMzgwMjZENDg1Nzg1NUIifQ.fVL3OuSL49PqbSXSmOvLk3jXP_vR5Du2mN4_bzH6iq4jTLdx8B4rsPJN3QPm0GxjpDZCB2coIWQQBbsd3M8wg53_2GfCs_Q4wP1rnchjT3Q4dRKEwBfPi1OFeaIxXbMToSiD1DycapubcEpRk-yEeH9DpO3t3ftdrDRqWWAd6fSyUnX40IuAddmID7hGB-L4_-VUoFkTt2iiGXRFgiyDi2dy339tvywHPEFyBOi-653bI1ticj8qBvbKNDEFwIO3RdnYknqZ0RzDsBjTZKfMtJ8InSMynF9jZRSwItDzp6nSXScJv83EtilsOl27TIVQzQGuGYjpgA2cUA6u3gdcvg';

export const COMPANY_DETAIL_EXTERNAL_ROUTE = `${env.VITE_ORGANIZATION_WEB_URL}/companies/:id`;

export const INSTALLATIONS_LIST_ROUTE = `${env.VITE_DEVICE_WEB_URL}/installations`;

export const PACKAGE_DETAIL_EXTERNAL_ROUTE = `${env.VITE_AUDIT_WEB_URL}/events/content-tracking?trackingIdentifier=:trackingIdentifier`;

export const STORAGE_UNIT_HISTORY_EXTERNAL_ROUTE = `${env.VITE_AUDIT_WEB_URL}/events/storage-unit-tracking?storageUnitName=:storageUnitName&pointId=:pointId`;

export const VITE_AUTH_DEVICE_AUTH_EP = `${env.VITE_IDENTITY_API_URL}/connect/deviceauthorization`;

export const AUTH_DEVICE_TOKEN_EP = `${env.VITE_IDENTITY_API_URL}/connect/token`;
export const AUTH_DEVICE_END_SESSION_EP = `${env.VITE_IDENTITY_API_URL}/connect/endsession`;
export const AUTH_DEVICE_CLIENT_ID = 'kiosk';
export const AUTH_DEVICE_GRANT = 'urn:ietf:params:oauth:grant-type:device_code';

// Kiosk mode is always in iframe therefore we can check if we are in iframe
// Cypress is using iframe as well so we need to check if we are not in Cypress
export const IS_KIOSK = window.self !== window.top && !window.Cypress;

export const CUSTOM_AUTH_TOKEN = isInCypress()
  ? CYPRESS_ACCESS_TOKEN
  : import.meta.env.VITE_CUSTOM_AUTH_TOKEN;

export const AUTH_CLIENT_ID = 'any_mob_web_app_interactive_client';

export const OPA_POLICIES_NAME = 'conteg';

export const CP_TENANT_ID = '8eb5dc6f-f539-4e0a-a656-98068823ccfe';

export const FORM_DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm';
