import { FORM_DATE_TIME_FORMAT } from 'config';
import { DateTime } from 'luxon';

export const parseFormDateToDateTime = (
  date: string,
  time: string
): DateTime => {
  return DateTime.fromFormat(`${date} ${time}`, FORM_DATE_TIME_FORMAT);
};

export const downloadImage = (data: string) => {
  const img = new Image();
  img.src = data;

  const a = document.createElement('a');
  a.href = data;
  a.download = 'kod-rezervace.png';
  a.click();
};
