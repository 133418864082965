import { useAuth, useLoadPolicy, useUserData } from '@conteg/auth';
import { Loading } from '@conteg/ui';
import { CUSTOM_AUTH_TOKEN, IS_KIOSK, OPA_POLICIES_NAME } from 'config';
import { createContext, useContext, useMemo } from 'react';
import { PolicyAction, PolicyResource } from 'types/policies-types';
import { useToken } from 'utils/device-auth/device-auth-store';
import env from 'utils/env/env';

type EvaluateContextType = (input: EvaluateInput) => boolean;

type PolicyLoaderProps = {
  children: React.ReactNode;
};

const EvaluateContext = createContext<EvaluateContextType>(() => false);

type EvaluateInput = {
  action: PolicyAction;
  resource: PolicyResource;
  subjectIsProvider: boolean;
  userRoles: string[];
};

export const PolicyLoader = ({ children }: PolicyLoaderProps) => {
  const { policy, isFetching } = useLoadPolicy({
    wasmFileUrl: `${env.VITE_POLICY_AGENT_URL}/${OPA_POLICIES_NAME}.wasm`,
    dataJsonFileUrl: `${env.VITE_POLICY_AGENT_URL}/${OPA_POLICIES_NAME}.data.json`,
  });

  if (isFetching) {
    return <Loading />;
  }

  const evaluate = (input: EvaluateInput) =>
    policy?.evaluate(input)?.[0]?.result?.allowed;

  return (
    <EvaluateContext.Provider value={evaluate}>
      {children}
    </EvaluateContext.Provider>
  );
};

export const usePolicyEvaluate = (
  action: PolicyAction | undefined,
  resource: PolicyResource | undefined
) => {
  const evaluate = useContext(EvaluateContext);
  const { user } = useAuth();
  const tokenOnKiosk = useToken();

  const tokenToParse = IS_KIOSK
    ? tokenOnKiosk
    : CUSTOM_AUTH_TOKEN || user?.access_token;

  const userData = useUserData(tokenToParse);

  const result = useMemo(
    () =>
      action &&
      resource &&
      evaluate({
        action,
        resource,
        subjectIsProvider: !!userData?.isProvider,
        userRoles: userData?.roleNames || [],
      }),
    [action, evaluate, resource, userData?.isProvider, userData?.roleNames]
  );

  return result;
};
