import {
  Alert,
  Button,
  Flex,
  Heading,
  Input,
  InputTypes,
  Loading,
  Modal,
  Option,
  showAlertModalError,
} from '@conteg/ui';
import Notification from 'components/notification/notification';
import { Select } from 'components/select/select';
import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  ServiceTypeEnum,
  StorageUnitDocument,
  useCreateNeverEndingReservationMutation,
  useTenantsQuery,
} from 'types/generated/graphql';
import { usePoint } from 'utils/device/device-store';
import { useInvalidateQueries } from 'utils/hooks/use-ivalidate-queries';

type FormInputs = {
  phoneNumber: string;
  tenantSubjectId: string;
  projectId: string;
  serviceType: string;
};

const CreateNeverEndingReservation = () => {
  const { t } = useTranslation();

  const { pointId } = usePoint();
  const { storageUnitId } = useParams<{ storageUnitId?: string }>();

  const invalidate = useInvalidateQueries();

  const [formNotice, setFormNotice] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);

  const formActions = useForm<FormInputs>({
    defaultValues: {
      phoneNumber: '',
    },
  });

  const {
    handleSubmit,
    reset,
    register,
    watch,
    setValue,
    formState: { errors },
  } = formActions;

  const { data: tenants, error: tenantsError } = useTenantsQuery(
    {
      pointId: pointId as string,
    },
    {
      enabled: !!pointId,
      gcTime: 0,
      staleTime: 0,
      select: (data) =>
        data.tenants.filter(
          (t) => t?.tenantServiceType === ServiceTypeEnum.BagToBox
        ),
    }
  );

  const tenantOptions: Option[] =
    tenants?.map((data) => ({
      label: data?.tenantName ?? '',
      value: data?.tenantSubjectId ?? '',
    })) ?? [];

  const watchTenantSubjectId = watch('tenantSubjectId');

  useEffect(() => {
    if (watchTenantSubjectId && tenants) {
      const selectedTenant = tenants.find(
        (tenant) => tenant?.tenantSubjectId === watchTenantSubjectId
      );

      if (selectedTenant) {
        setValue('projectId', selectedTenant.projectId);
      }
    }
  }, [setValue, tenants, watchTenantSubjectId]);

  const { mutate: createNeverEndingReservation, isPending: isCreating } =
    useCreateNeverEndingReservationMutation();

  const handleCreateReservation: SubmitHandler<FormInputs> = ({
    phoneNumber,
    tenantSubjectId,
    projectId,
  }) => {
    setFormNotice('');

    createNeverEndingReservation(
      {
        request: {
          pointId: pointId as string,
          storageUnitId: storageUnitId as string,
          phoneNumber,
          tenantSubjectId,
          serviceType: ServiceTypeEnum.BagToBox,
          projectId,
        },
      },
      {
        onSuccess: () => {
          alert(t('Page.StorageUnit.Actions.NeverEnding.Reservation.Success'));
          invalidate([StorageUnitDocument]);
          setOpenModal(false);
          reset();
        },
        onError: (error) => {
          if (error?.toString().includes('PhoneNumber')) {
            setFormNotice(
              'Page.StorageUnit.Actions.NeverEnding.Reservation.Error.PhoneNumber'
            );
            return;
          }
          showAlertModalError(
            t('Page.StorageUnit.Actions.NeverEnding.Reservation.Error'),
            error
          );
        },
      }
    );
  };

  if (isCreating) {
    return (
      <Flex alignItems="center" justifyContent="center">
        <Loading
          text={t(
            'Page.StorageUnit.Actions.NeverEnding.Reservation.Create.Loading'
          )}
        />
      </Flex>
    );
  }

  if (tenantsError) {
    return (
      <Alert
        error={tenantsError}
        title={t('Error.TenantsLoadError')}
        type="error"
      />
    );
  }

  return (
    <>
      <Modal
        testId="create-reservation-modal"
        size="l"
        showCloseButton={true}
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
      >
        <FormProvider {...formActions}>
          <form onSubmit={handleSubmit(handleCreateReservation)}>
            <Flex flexDirection="column" alignItems="center" gap="5rem">
              <Heading
                title={t(
                  'Page.StorageUnit.Actions.NeverEnding.Reservation.Create.Heading'
                )}
                variant="h2"
              />
              {formNotice && (
                <Notification
                  testId="create-never-ending-reservation-notification"
                  message={formNotice}
                />
              )}
              <Select
                name="tenantSubjectId"
                options={tenantOptions}
                label={t(
                  'Page.StorageUnit.Actions.NeverEndingReservation.Select.Tenant'
                )}
                isNullable={false}
                required
                error={errors?.tenantSubjectId || undefined}
              />
              <Input
                testId="phoneNumber"
                type={InputTypes.TEL}
                label={t('Page.StorageUnit.Actions.Input.PhoneNumber')}
                placeholder={t('Page.StorageUnit.Actions.Input.PhoneNumber')}
                required
                error={
                  errors.phoneNumber?.message && t(errors.phoneNumber.message)
                }
                {...register('phoneNumber', { required: true })}
              />
              <Flex alignItems="center" gap="5rem">
                <Button
                  testId="create-never-ending-reservation-confirmation-button"
                  variant="primary"
                  size="xl"
                  title={t(
                    'Page.StorageUnit.Actions.Input.NeverEndingReservation.Create.ConfirmButton'
                  )}
                  disabled={isCreating}
                  type="submit"
                />
                <Button
                  testId="close-create-never-ending-reservation-modal-button"
                  variant="danger"
                  size="xl"
                  type="button"
                  title={t(
                    'Page.StorageUnit.Actions.Input.NeverEndingReservation.Create.CancelButton'
                  )}
                  onClick={() => setOpenModal(false)}
                />
              </Flex>
            </Flex>
          </form>
        </FormProvider>
      </Modal>
      <Button
        testId="create-never-ending-reservation-button"
        variant="primary"
        size="xl"
        title={t('Page.StorageUnit.Actions.NeverEndingReservation.Create')}
        onClick={() => setOpenModal(true)}
      />
    </>
  );
};

export default CreateNeverEndingReservation;
