import { useTranslation } from 'react-i18next';
import { TransKey } from 'utils/localization/cs';

export const useTypedTranslation = () => {
  const { t: i18nT, ...rest } = useTranslation();

  const t = (key: TransKey, params?: Record<string, string | number>) => {
    return i18nT(key, params);
  };

  return { t, ...rest };
};
