import { useAuth } from '@conteg/auth';
import Login from 'pages/login/login';
import NotFound from 'pages/not-found/not-found';
import StorageUnitDetail from 'pages/storage-unit-detail/storage-unit-detail';
import StorageUnitList from 'pages/storage-unit-list/storage-unit-list';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { useIsAuthenticated } from 'utils/device-auth/device-auth-store';
import Layout from 'utils/layout/layout';

import ProtectedRoute from './protected-route';
import { appRoutes } from './routes';

type Route = RouteProps & {
  protected?: boolean;
};

const routes: Route[] = [
  { path: '/', element: <Login /> },
  {
    path: appRoutes.home,
    element: <StorageUnitList />,
    protected: true,
  },
  {
    path: appRoutes.storageUnitDetail,
    element: <StorageUnitDetail />,
    protected: true,
  },
];

const Router = () => {
  const { isAuthenticated } = useAuth();
  const isAuthenticatedOnKiosk = useIsAuthenticated();

  const userAuthenticated = isAuthenticated || isAuthenticatedOnKiosk;

  return (
    <Routes>
      <Route element={<Layout />}>
        {routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              route.protected ? (
                <ProtectedRoute isAuthenticated={userAuthenticated}>
                  {route.element}
                </ProtectedRoute>
              ) : (
                route.element
              )
            }
          />
        ))}
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default Router;
