import { Option, Select as ContegUiSelect } from '@conteg/ui';
import { InputHTMLAttributes } from 'react';
import {
  Controller,
  FieldError,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form';
import { useTypedTranslation } from 'utils/hooks/use-typed-translation';

type SelectProps<T extends FieldValues> =
  InputHTMLAttributes<HTMLInputElement> & {
    name: FieldPath<T>;
    options: Option[];
    label: string;
    testId?: string;
    disabled?: boolean;
    hasAutocomplete?: boolean;
    isNullable?: boolean;
    required?: boolean;
    error: FieldError | undefined;
  };

export const Select = <T extends FieldValues>({
  name,
  options,
  label,
  testId,
  disabled,
  hasAutocomplete,
  isNullable = true,
  required = false,
  error,
}: SelectProps<T>) => {
  const { control } = useFormContext();
  const { t } = useTypedTranslation();

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field: { value, onChange } }) => (
        <ContegUiSelect
          hasAutocomplete={hasAutocomplete}
          width="100%"
          testId={testId || `textInput-${name}`}
          defaultSingleValue={value}
          userSingleValue={value}
          label={label}
          onSingleValueChange={onChange}
          options={options}
          error={error && t('Error.Form.Required')}
          disabled={disabled}
          isNullable={isNullable}
        />
      )}
    />
  );
};
