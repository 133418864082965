import { IFrameMessage } from '@conteg/ui';
import { logger } from 'utils/logging/init';

const parentUrl = window.location.ancestorOrigins[0];

/**
 * Hook for sending post messages to the parent window.
 *
 * @returns A hook for sending post messages to the parent window.
 */
export const sendPostMessage = (message: IFrameMessage) => {
  if (!parentUrl) {
    logger.warn(
      `Parent URL is not available for sending post message: ${JSON.stringify(
        message
      )}`
    );
    return;
  }
  parent.postMessage(message, parentUrl);
};
