import {
  AUTH_DEVICE_CLIENT_ID,
  AUTH_DEVICE_END_SESSION_EP,
  AUTH_DEVICE_GRANT,
  AUTH_DEVICE_TOKEN_EP,
  VITE_AUTH_DEVICE_AUTH_EP,
} from 'config';
import {
  createRequestOptions,
  objectToUrl,
} from 'utils/device-auth/auth-utils';
import {
  AuthRequestData,
  AuthStore,
  LoginData,
  PollingRequestData,
} from 'utils/device-auth/types';
import env from 'utils/env/env';
import { dispatchLeavePage } from 'utils/post-messages/dispatch-leave-page';
import { TimerManager } from 'utils/timer-manager/timer-manager';
import { StoreApi } from 'zustand';

export const loginInit = async (
  set: StoreApi<AuthStore>['setState'],
  get: StoreApi<AuthStore>['getState'],
  forceInit?: boolean
) => {
  if (
    (get().loginData ||
      get().status === 'polling' ||
      get().status === 'loading') &&
    !forceInit
  )
    return;

  // Reset loginData to re-initialize flow correctly
  set(() => ({
    loginData: null,
    token: '',
    expiresIn: 0,
    isAuthenticated: false,
  }));

  set(() => ({ status: 'loading' }));
  try {
    const body: AuthRequestData = {
      client_id: AUTH_DEVICE_CLIENT_ID ?? '',
      client_secret: env.VITE_AUTH_DEVICE_CLIENT_SECRET ?? '',
      scope: env.VITE_AUTH_DEVICE_SCOPE ?? '',
    };

    const response = await fetch(
      VITE_AUTH_DEVICE_AUTH_EP,
      createRequestOptions(objectToUrl(body))
    );

    if (response.ok) {
      const data: LoginData = await response.json();

      set(() => ({
        loginData: data,
      }));
    } else {
      get().actions.setError('Error.LoginInit');
    }
  } catch (error) {
    get().actions.setError('Error.LoginInit');
  }
};

export const loginPolling = async (
  set: StoreApi<AuthStore>['setState'],
  get: StoreApi<AuthStore>['getState']
) => {
  const loginData = get().loginData;
  const status = get().status;

  if (
    !loginData ||
    status === 'polling' ||
    status === 'error' ||
    status === 'done'
  )
    return;

  set(() => ({ status: 'polling' }));

  const body: PollingRequestData = {
    client_id: AUTH_DEVICE_CLIENT_ID ?? '',
    client_secret: env.VITE_AUTH_DEVICE_CLIENT_SECRET ?? '',
    grant_type: AUTH_DEVICE_GRANT ?? '',
    device_code: loginData.device_code,
  };

  const requestOptions = createRequestOptions(objectToUrl(body));
  const pollingId = 'login-polling';

  const polling = async () => {
    try {
      const response = await fetch(AUTH_DEVICE_TOKEN_EP, requestOptions);
      const data = await response.json();

      if (response.ok) {
        set(() => ({
          token: data.access_token,
          expiresIn: data.expires_in,
          isAuthenticated: true,
          status: 'done',
        }));
        TimerManager.stopTimer(pollingId, 'interval');
        get().actions.revokeTokenAfterExpiration();
      } else if (
        data.error &&
        data.error !== 'authorization_pending' &&
        data.error !== 'slow_down'
      ) {
        get().actions.setError('Error.LoginFlowExpired');
        dispatchLeavePage();
        TimerManager.stopTimer(pollingId, 'interval');
      }
    } catch (err) {
      get().actions.setError('Error.LoginInit');
      TimerManager.stopTimer(pollingId, 'interval');
    }
  };

  TimerManager.startTimer(pollingId, 'interval', loginData.interval, polling);
};

export const revokeToken = (
  set: StoreApi<AuthStore>['setState'],
  get: StoreApi<AuthStore>['getState']
) => {
  const expiresIn = get().expiresIn;
  const tokenExpirationId = 'token-expiration';

  const revokeToken = () => {
    set(() => ({
      loginData: null,
      token: '',
      expiresIn: 0,
      isAuthenticated: false,
      status: 'idle',
    }));
    window.location.assign('/');
  };

  TimerManager.startTimer(tokenExpirationId, 'timeout', expiresIn, revokeToken);
};

export const logout = async (
  set: StoreApi<AuthStore>['setState'],
  get: StoreApi<AuthStore>['getState']
) => {
  try {
    await fetch(AUTH_DEVICE_END_SESSION_EP, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${get().token}`,
      },
      credentials: 'include',
    });
  } catch (err) {
    //TODO better handling
    // eslint-disable-next-line no-console
    console.error(err);
  } finally {
    set(() => ({
      loginData: null,
      token: '',
      expiresIn: 0,
      isAuthenticated: false,
      status: 'idle',
    }));
    TimerManager.stopAllTimers();
  }
};
