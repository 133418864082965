import {
  Button,
  ConfirmModal,
  ErrorDetailInfo,
  Flex,
  Loading,
  Modal,
  showAlertModalError,
  styled,
} from '@conteg/ui';
import WiFiIconOFF from 'assets/icons/wifi-off.svg';
import WiFiIconON from 'assets/icons/wifi-on.svg';
import Breadcrumbs from 'components/breadcrumbs/breadcrumbs';
import { CreateDeliveryReservation } from 'components/create-delivery-reservation/create-delivery-reservation';
import Notification from 'components/notification/notification';
import { PolicyGuardWrapper } from 'components/policy-guard/policy-guard';
import {
  OpenModal,
  OpenModalTypes,
} from 'pages/storage-unit-detail/components/actions/allocation-actions';
import OpenWithPin from 'pages/storage-unit-detail/components/actions/open-unit/open-with-pin';
import DataGrid from 'pages/storage-unit-list/components/data-grid';
import {
  isServiceModule,
  sortStorageUnitByName,
} from 'pages/storage-unit-list/help-functions';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  useAppConfigQuery,
  useOpenEmptyStorageUnitsMutation,
  useStorageUnitsQuery,
} from 'types/generated/graphql';
import { usePoint } from 'utils/device/device-store';
import { useBiggestStorageUnitSize } from 'utils/hooks/use-bigget-storage-unit-size';
import { notEmpty } from 'utils/not-empty/not-empty';

const PointAddress = styled.div`
  font-size: 4rem;
  font-weight: 500;
  margin: 0 2rem;
`;

const StorageUnitsAmount = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 4rem;
  font-weight: 600;

  p {
    color: ${({ theme }) => theme.colors.additional.success};
  }
  span {
    color: ${({ theme }) => theme.colors.additional.error};
  }
`;

const WifiIcon = styled.img`
  width: 6rem;
  height: auto;
`;

const CHUNK_SIZE = 10;

const splitArrayIdsByChunks = (array: string[], chunkSize: number) => {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
};

const StorageUnitList = () => {
  const { t } = useTranslation();

  const { pointId } = usePoint();

  const [openModal, setOpenModal] = useState<OpenModal>(null);
  const [showModalCloseConfirmation, setShowModalCloseConfirmation] =
    useState<boolean>(false);
  const [openAllEmptyUnits, setOpenAllEmptyUnits] = useState<boolean>(false);
  const [actionResult] = useState<string>('');
  const {
    biggestStorageUnitSize,
    isLoading: isLoadingSizes,
    error: storageUnitSizesError,
  } = useBiggestStorageUnitSize();

  const { mutateAsync, isPending: isOpening } =
    useOpenEmptyStorageUnitsMutation();

  const {
    data,
    isLoading: isLoadingStorageUnits,
    error: storageUnitsError,
    isFetching,
    isRefetching,
    refetch,
  } = useStorageUnitsQuery(
    {
      pointId: pointId as string,
    },
    {
      gcTime: 0,
      staleTime: 0,
    }
  );

  // TODO: Create a new query to get only the point online/offline stat, or websocket
  const {
    data: appConfigData,
    error: appConfigError,
    isLoading: isLoadingAppConfig,
  } = useAppConfigQuery(
    {
      pointId: pointId as string,
    },
    {
      enabled: !!pointId,
      // Both times have to be set 0 zero in order to display the latest state of online/offline  state of the point
      // TODO:FIXME: Implement websocket
      gcTime: 0,
      staleTime: 0,
    }
  );

  const allUsableStorageUnits = (data?.storageUnits ?? []).filter(
    (unit) => !isServiceModule(unit)
  );

  const emptyUsableStorageUnits = allUsableStorageUnits.filter(
    (storageUnit) => storageUnit?.isEmpty
  );

  const dataTableStorageUnitList = data?.storageUnits
    ? data?.storageUnits?.sort(sortStorageUnitByName).filter(notEmpty)
    : [];

  const storageUnitsIds =
    emptyUsableStorageUnits
      .filter(notEmpty)
      .map((storage) => storage?.storageUnit.id) || [];

  const openAllStorageUnits = async () => {
    const chunks = splitArrayIdsByChunks(storageUnitsIds, CHUNK_SIZE);

    for (let index = 0; index < chunks.length; index++) {
      const chunk = chunks[index];
      try {
        await mutateAsync({
          pointId: pointId as string,
          storageUnitIds: chunk,
        });
      } catch (error) {
        showAlertModalError(
          t('Page.StorageUnitList.Actions.OpenEmptyBoxes.Error'),
          error
        );
        return;
      }
    }
  };

  const handleModalConfirmOpenAllEmptyUnits = () => {
    if (!openAllEmptyUnits) {
      setOpenModal(null);
      setShowModalCloseConfirmation(false);
      return;
    }
    openAllStorageUnits();
    // Reset the state to false after successfully opening all storage units
    setOpenAllEmptyUnits(false);
    // This hook closes the confirmation modal
    setShowModalCloseConfirmation(false);
  };

  const handleCloseModalConfirmOpenAllEmptyUnits = () => {
    //This hook setShowModalCloseConfirmation closes confirmation modal
    setShowModalCloseConfirmation(false);
    //This hook setOpenAllEmptyUnits is for translation of modal title and description
    setOpenAllEmptyUnits(false);
  };

  if (!pointId) {
    return (
      <Flex alignItems="center" justifyContent="center">
        <Notification testId="data-url" message="Error.InvalidWebUrl" />
      </Flex>
    );
  }

  if (isLoadingStorageUnits || isLoadingSizes || isLoadingAppConfig) {
    return (
      <Flex alignItems="center" justifyContent="center">
        <Loading testId="data-loading" text={t('Notice.LoadingData')} />
      </Flex>
    );
  }

  if (storageUnitSizesError) {
    return (
      <ErrorDetailInfo
        title={t('Error.StorageUnitSizesError')}
        error={storageUnitsError}
      />
    );
  }

  if (storageUnitsError) {
    return (
      <ErrorDetailInfo
        title={t('Error.StorageUnitsLoadError')}
        error={storageUnitsError}
      />
    );
  }

  if (appConfigError) {
    return (
      <ErrorDetailInfo
        title={t('Error.AppConfigLoadError')}
        error={appConfigError}
      />
    );
  }

  return (
    <Flex flexDirection="column" gap="6rem">
      <Flex flexDirection="column" gap="5rem">
        <Flex alignItems="center" gap="2rem">
          {appConfigData?.appConfig.pointFullName && (
            <PointAddress>
              {appConfigData?.appConfig?.pointFullName}
            </PointAddress>
          )}
          {appConfigData?.appConfig?.isPointOnline ? (
            <WifiIcon src={WiFiIconON} />
          ) : (
            <WifiIcon src={WiFiIconOFF} />
          )}
        </Flex>
        <Breadcrumbs />
      </Flex>

      {actionResult && (
        <Notification testId="action-result" message={actionResult} />
      )}
      <Flex justifyContent="space-between" alignItems="center">
        <Flex gap="2rem" alignItems="center">
          <StorageUnitsAmount>
            <Trans
              i18nKey="Page.StorageUnitList.AvailableStorageUnits"
              components={{ p: <p />, span: <span /> }}
              values={{
                emptyUsableStorageUnits: emptyUsableStorageUnits?.length,
                allUsableStorageUnits: allUsableStorageUnits?.length,
              }}
            />
          </StorageUnitsAmount>
          <PolicyGuardWrapper action="openAllEmptyStorUnits">
            <Button
              testId="open-empty-storage-units"
              title={t('Page.StorageUnitList.Actions.OpenEmptyBoxes')}
              onClick={() => {
                setOpenAllEmptyUnits(true);
                setShowModalCloseConfirmation(true);
              }}
              variant="primary"
              size="xs"
              disabled={isOpening}
            />
          </PolicyGuardWrapper>
          {biggestStorageUnitSize && appConfigData?.appConfig.projectId && (
            <PolicyGuardWrapper action="canCreateDeliveryReservation">
              <CreateDeliveryReservation
                maxWidth={biggestStorageUnitSize?.innerWidth}
                maxHeight={biggestStorageUnitSize?.innerHeight}
                maxDepth={biggestStorageUnitSize?.innerDepth}
                pointId={pointId}
                projectId={appConfigData?.appConfig.projectId}
              />
            </PolicyGuardWrapper>
          )}
          <PolicyGuardWrapper action="canOpenStorageUnitWithPin">
            <Button
              testId="open-storage-unit"
              title={t('Page.StorageUnit.Actions.Open')}
              onClick={() => setOpenModal(OpenModalTypes.OpenStorageUnit)}
              variant="primary"
              size="xs"
            />
          </PolicyGuardWrapper>
          <Button
            testId="refresh-storage-unit-list-button"
            title={t('Page.StorageUnitList.Actions.Refresh')}
            onClick={refetch}
            variant="primary"
            size="xs"
            disabled={isLoadingStorageUnits || isFetching || isRefetching}
          />
        </Flex>
      </Flex>
      <DataGrid
        storageUnitList={dataTableStorageUnitList}
        isLoading={isLoadingStorageUnits || isFetching || isRefetching}
      />
      <Modal
        testId="unit-actions-modal"
        size="l"
        showCloseButton={true}
        isOpen={openModal !== null}
        onClose={() => setShowModalCloseConfirmation(true)}
      >
        <OpenWithPin openWithoutVerifyStep />
      </Modal>
      <ConfirmModal
        zIndexOffset={1000}
        modalTitle={
          openAllEmptyUnits
            ? t(
                'Page.StorageUnit.Actions.OpenAllEmptyStorageUnits.ConfirmTitle'
              )
            : t('Page.StorageUnit.Actions.CloseAction.ConfirmTitle')
        }
        modalDescription={
          openAllEmptyUnits
            ? ''
            : t('Page.StorageUnit.Actions.CloseAction.ConfirmText')
        }
        isOpen={showModalCloseConfirmation}
        confirm={{
          text: openAllEmptyUnits
            ? t(
                'Page.StorageUnit.Actions.OpenAllEmptyStorageUnits.ConfirmButton'
              )
            : t('Page.StorageUnit.Actions.CloseAction.ConfirmButton'),
          variant: 'danger',
        }}
        cancelText={
          openAllEmptyUnits
            ? t(
                'Page.StorageUnit.Actions.OpenAllEmptyStorageUnits.CancelButton'
              )
            : t('Page.StorageUnit.Actions.CloseAction.CancelButton')
        }
        onClose={handleCloseModalConfirmOpenAllEmptyUnits}
        onConfirm={handleModalConfirmOpenAllEmptyUnits}
      />
    </Flex>
  );
};

export default StorageUnitList;
