import { showAlertModalError } from '@conteg/ui';
import { DateTime } from 'luxon';
import { OneTimePasswordProps } from 'pages/storage-unit-detail/components/actions/one-time-password/one-time-password';
import { SetStateAction, useEffect, useState } from 'react';
import {
  ReservationStatus,
  useGenerateStorageUnitOneTimePasswordMutation,
} from 'types/generated/graphql';
import { usePoint } from 'utils/device/device-store';
import { TimerManager } from 'utils/timer-manager/timer-manager';

const refetchTimerId = 'refetch-otp';

export const useGetOtp = (
  storageUnitId: string,
  reservationStatus: ReservationStatus | null | undefined,
  startFetching?: boolean
): {
  otp: OneTimePasswordProps;
  setStartFetching: React.Dispatch<SetStateAction<boolean>>;
} => {
  const { pointId } = usePoint();

  const [fetchData, setFetchData] = useState<boolean>(!!startFetching);
  const [expiration, setExpiration] = useState<number>(0);
  const [oneTimePassword, setOneTimePassword] = useState<string>('');

  const { mutateAsync, isPending } =
    useGenerateStorageUnitOneTimePasswordMutation({
      onError: (error) =>
        showAlertModalError(
          'Error.GenerateStorageUnitOneTimePasswordMutation',
          error
        ),
    });

  // Fetch OTP data
  useEffect(() => {
    const getOTP = async () => {
      const response = await mutateAsync({
        pointId: pointId as string,
        storageUnitId,
      });

      const otpDateTime = DateTime.fromISO(
        response.generateStorageUnitOneTimePassword.validTo
      );

      setExpiration(
        otpDateTime.toUnixInteger() - DateTime.now().toUnixInteger()
      );
      setOneTimePassword(
        response.generateStorageUnitOneTimePassword.oneTimePassword ?? ''
      );
    };

    if (expiration === 0 && !isPending && !!reservationStatus && fetchData) {
      getOTP();
    }
  }, [
    fetchData,
    isPending,
    mutateAsync,
    expiration,
    pointId,
    reservationStatus,
    storageUnitId,
  ]);

  // Set re-fetch count down
  useEffect(() => {
    if (
      expiration !== 0 &&
      !TimerManager.isRunning(refetchTimerId, 'interval')
    ) {
      TimerManager.startTimer(refetchTimerId, 'interval', 1, () => {
        setExpiration((value) => value - 1);
      });
    }

    if (expiration === 0) {
      TimerManager.stopTimer(refetchTimerId, 'interval');
    }
    return () => TimerManager.stopTimer(refetchTimerId, 'interval');
  }, [expiration]);

  return {
    otp: {
      oneTimePassword,
      expiration,
      isLoading: isPending,
    },
    setStartFetching: setFetchData,
  };
};
