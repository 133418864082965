import {
  Button,
  Flex,
  Heading,
  Loading,
  showAlertModalError,
} from '@conteg/ui';
import Error from 'components/error/error';
import Notification from 'components/notification/notification';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTerminateAllocationV2Mutation } from 'types/generated/graphql';
import { appRoutes } from 'utils/routing/routes';

const TerminateAllocation = ({ storageUnitId }: { storageUnitId: string }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formNotice, setFormNotice] = useState<string>('');

  const {
    mutate: terminateAllocation,
    isPending,
    error,
  } = useTerminateAllocationV2Mutation();

  const handleTerminateAllocation = () => {
    setFormNotice('');
    terminateAllocation(
      {
        storageUnitId,
      },
      {
        onSuccess: () => {
          alert(t('Page.StorageUnit.Actions.Terminate.Allocation.Success'));
          navigate(appRoutes.home);
        },
        onError: (error) =>
          showAlertModalError(
            t('Page.StorageUnit.Actions.Terminate.Allocation.Error'),
            error
          ),
      }
    );
  };

  if (isPending) {
    return (
      <Flex alignItems="center" justifyContent="center">
        <Loading
          text={t('Page.StorageUnit.Actions.Terminate.Allocation.Loading')}
        />
      </Flex>
    );
  }

  if (error) {
    return <Error message="Error.TerminateAllocation" />;
  }

  return (
    <Flex flexDirection="column" alignItems="center" gap="5rem">
      <Heading
        variant="h2"
        title={t('Page.StorageUnit.Actions.Terminate.Allocation.Heading')}
      />
      {formNotice && (
        <Notification
          testId="terminate-allocation-notification"
          message={formNotice}
        />
      )}
      <Button
        testId="terminate-allocation-confirm-button"
        variant="danger"
        size="l"
        title={t('Page.StorageUnit.Actions.Terminate.Allocation')}
        onClick={handleTerminateAllocation}
      />
    </Flex>
  );
};

export default TerminateAllocation;
