import { Row } from '@tanstack/react-table';
import { StorageUnitListFragment } from 'types/generated/graphql';

const getSortNumber = (row: StorageUnitListFragment) => {
  if (row.storageUnit.storageUnitSize?.isServiceModule) return -1;
  return row.isEmpty ? 1 : 0;
};

export const sortByEmpty = (
  rowA: Row<StorageUnitListFragment>,
  rowB: Row<StorageUnitListFragment>
) => {
  return getSortNumber(rowA.original) - getSortNumber(rowB.original);
};
