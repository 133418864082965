import { Heading } from '@conteg/ui';
import { useEffect, useState } from 'react';
import { formatTime } from 'utils/timer-manager/format-time';
import { TimerManager } from 'utils/timer-manager/timer-manager';

type CountdownTimerProps = {
  time: number;
  countdownId: string;
  onFinish?: () => void;
  resetOnFinish?: boolean;
};

const CountdownTimer = ({
  time,
  countdownId,
  onFinish,
  resetOnFinish,
}: CountdownTimerProps) => {
  const [remainingTime, setRemainingTime] = useState<number>(time);

  useEffect(() => {
    setRemainingTime(time);
  }, [time]);

  useEffect(() => {
    if (
      remainingTime !== 0 &&
      !TimerManager.isRunning(countdownId, 'interval')
    ) {
      TimerManager.startTimer(countdownId, 'interval', 1, () => {
        setRemainingTime((value) => value - 1);
      });
    }

    if (remainingTime === 0) {
      TimerManager.stopTimer(countdownId, 'interval');
      if (onFinish) onFinish();
      if (resetOnFinish) {
        setRemainingTime(time);
      }
    }

    return () => TimerManager.stopTimer(countdownId, 'interval');
  }, [onFinish, remainingTime, countdownId, resetOnFinish, time]);

  return (
    <Heading
      testId={countdownId}
      variant="h3"
      title={formatTime(remainingTime)}
    />
  );
};

export default CountdownTimer;
