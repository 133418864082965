import { Button, Flex, Heading, Modal, Text } from '@conteg/ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useActions } from 'utils/device-auth/device-auth-store';
import { dispatchLeavePage } from 'utils/post-messages/dispatch-leave-page';

const LayoutKioskLogout = () => {
  const { t } = useTranslation();
  const { logout } = useActions();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  useEffect(() => {
    if (isConfirmed) {
      logout();
      dispatchLeavePage();
    }
    // We don't want more dependencies here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmed]);

  return (
    <>
      <Button
        testId="layout-logout-button"
        onClick={() => setIsModalOpen(true)}
        variant="primary"
        size="xl"
        title={t('Kiosk.Layout.Button.BackToCustomersApp')}
      />
      <Modal isOpen={isModalOpen} size="l">
        <Heading title={t('Kiosk.Layout.Modal.Title')} variant="h2" />
        <Flex
          flexDirection="column"
          justifyContent="center"
          gap="1rem"
          marginTop="2rem"
        >
          {!isConfirmed && (
            <>
              <Text content={t('Kiosk.Layout.Modal.Description')} />
              <Flex justifyContent="flex-end" width="100%" gap="2rem">
                <Button
                  title={t('Kiosk.Layout.Modal.Button.Cancel')}
                  onClick={() => setIsModalOpen(false)}
                  variant="secondary"
                  testId="cancel-action-button"
                  size="s"
                />
                <Button
                  size="s"
                  title={t('Kiosk.Layout.Modal.Button.Confirm')}
                  variant="primary"
                  testId="confirm-action-button"
                  onClick={() => setIsConfirmed(true)}
                />
              </Flex>
            </>
          )}
        </Flex>
      </Modal>
    </>
  );
};

export default LayoutKioskLogout;
