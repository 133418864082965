type TimerType = 'interval' | 'timeout';

export type Timer = {
  id: number;
  type: TimerType;
  timerId: string;
};

export const TimerManager = {
  startTimer: (
    timerId: string,
    type: TimerType,
    delay: number,
    callback: CallableFunction
  ): void => {
    // Stop timer if already exists to start it anew
    TimerManager.stopTimer(timerId, type);
    let id: number;
    if (type === 'interval') id = window.setInterval(callback, delay * 1000);
    else {
      id = window.setTimeout(() => {
        callback();
        TimerManager.removeTimer(timerId, 'timeout');
      }, delay * 1000);
    }
    window.runningTimers.push({
      id,
      type,
      timerId,
    });
  },
  stopTimer: (timerId: string, type: TimerType): void => {
    TimerManager.checkTimersArray();
    const timer = window.runningTimers.find(
      (i) => i.timerId === timerId && i.type === type
    );
    if (timer) {
      if (type === 'interval') clearInterval(timer.id);
      else clearTimeout(timer.id);
      TimerManager.removeTimer(timerId, type);
    }
  },
  stopAllTimers: (): void => {
    TimerManager.checkTimersArray();
    window.runningTimers.forEach((timer) => {
      if (timer.type === 'interval') clearInterval(timer.id);
      else clearTimeout(timer.id);
    });
    window.runningTimers = [];
  },
  removeTimer: (timerId: string, type: TimerType) => {
    TimerManager.checkTimersArray();
    window.runningTimers = window.runningTimers.filter(
      (i) => i.timerId !== timerId || i.type !== type
    );
  },
  isRunning: (timerId: string, type: TimerType) => {
    return !!window.runningTimers.find(
      (t) => t.timerId === timerId && t.type === type
    );
  },
  checkTimersArray: () => {
    if (!Array.isArray(window.runningTimers))
      throw new Error(
        'TimerManager is being used without initialization. Import "timer-manager-init.ts" before using TimerManager.'
      );
  },
};
