import { Box, Icon, styled, Text } from '@conteg/ui';
import { useTranslation } from 'react-i18next';
import { StorageUnitFeature } from 'types/generated/graphql';

const FeaturesTable = styled.table``;
const FeaturesTableBody = styled.tbody``;
const FeaturesTableRow = styled.tr``;
const FeaturesTableTextCell = styled.td``;
const FeaturesTableIconCell = styled.td`
  width: 8rem;
`;

type StorageUnitFeaturesDetailProps = {
  onReservation: boolean;
  storageUnitFeatures: StorageUnitFeature[];
};

const StorageUnitFeaturesDetail = ({
  storageUnitFeatures,
  onReservation,
}: StorageUnitFeaturesDetailProps) => {
  const { t } = useTranslation();

  const getFeatureIcon = (feature: StorageUnitFeature) => {
    switch (feature) {
      case StorageUnitFeature.Cooling:
        return <Icon name="snowFlake" />;
      case StorageUnitFeature.AcSocket:
        return <Icon name="chargingCable" />;
    }
  };

  const getFeatureLine = (feature: StorageUnitFeature) => (
    <FeaturesTableRow key={feature}>
      <FeaturesTableIconCell>{getFeatureIcon(feature)}</FeaturesTableIconCell>
      <FeaturesTableTextCell>
        <Text variant="large" content={t(feature)} />
      </FeaturesTableTextCell>
    </FeaturesTableRow>
  );

  if (storageUnitFeatures.length === 0) {
    return null;
  }

  return (
    <Box>
      <Text
        variant={onReservation ? 'highlight' : 'normal'}
        content={
          onReservation
            ? t('Page.StorageUnitDetail.RequiredStorageUnitFeatures')
            : t('Page.StorageUnitDetail.StorageUnitFeatures')
        }
      />
      <FeaturesTable>
        <FeaturesTableBody>
          {storageUnitFeatures.map(getFeatureLine)}
        </FeaturesTableBody>
      </FeaturesTable>
    </Box>
  );
};

export default StorageUnitFeaturesDetail;
