import { Checkbox } from '@conteg/ui';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type FormCheckboxProps = {
  name: string;
  label: string;
  testId?: string;
  disabled?: boolean;
};

export const FormCheckbox = ({
  name,
  label,
  testId,
  disabled,
}: FormCheckboxProps) => {
  const { control } = useFormContext();

  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name={name.toString()}
      defaultValue={false}
      render={({ field, fieldState }) => (
        <Checkbox
          onChange={field.onChange}
          defaultValue={field.value || false}
          value={field.value}
          testId={testId || `checkbox-${name.toString()}`}
          label={label}
          error={fieldState.error?.message && t(fieldState.error?.message)}
          disabled={disabled}
        />
      )}
    />
  );
};
