import { Flex, styled, Text } from '@conteg/ui';
import czFlag from 'assets/country-flags/cz.svg';
import deFlag from 'assets/country-flags/de.svg';
import esFlag from 'assets/country-flags/es.svg';
import frFlag from 'assets/country-flags/fr.svg';
import gbFlag from 'assets/country-flags/gb.svg';
import skFlag from 'assets/country-flags/sk.svg';
import uaFlag from 'assets/country-flags/ua.svg';
import { useTranslation } from 'react-i18next';
import { Language } from 'types/generated/graphql';

const flagResolver: Record<Language, string> = {
  CS: czFlag,
  DE: deFlag,
  ES: esFlag,
  FR: frFlag,
  EN: gbFlag,
  SK: skFlag,
  UK: uaFlag,
};

type ReservationLanguageDetailProps = {
  languageCode: Language;
};

const FlagImage = styled.img`
  width: 4rem;
`;

const ReservationLanguageDetail = ({
  languageCode,
}: ReservationLanguageDetailProps) => {
  const { t } = useTranslation();

  return (
    <Flex gap="2rem">
      <Text variant="large" content={t('Page.Reservation.Language')} />
      <FlagImage src={flagResolver[languageCode]} />
      <Text variant="large" content={t(`Language.${languageCode}`)} />
    </Flex>
  );
};

export default ReservationLanguageDetail;
