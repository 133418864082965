import {
  Breadcrumb,
  Breadcrumbs as ContegBreadcrumbs,
  styled,
} from '@conteg/ui';
import { IS_KIOSK } from 'config';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import ConditionalWrapper from 'utils/conditional-wrapper/conditional-wrapper';
import { useBreadcrumbs } from 'utils/hooks/use-breadcrumbs';

const BreadcrumbsWrapper = styled.div`
  button {
    font-size: 4rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white};
    &:last-of-type {
      color: ${({ theme }) => theme.colors.secondary.grey200};
    }
  }

  div {
    width: 1.5rem;
    height: 1.5rem;
    &:last-of-type {
      border-bottom: 1px solid ${({ theme }) => theme.colors.white};
      border-left: 1px solid ${({ theme }) => theme.colors.white};
    }
  }
`;

const Breadcrumbs = () => {
  const navigate = useNavigate();
  const items = useBreadcrumbs();

  return (
    <ConditionalWrapper
      condition={IS_KIOSK}
      wrap={(children: ReactNode) => (
        <BreadcrumbsWrapper>{children}</BreadcrumbsWrapper>
      )}
    >
      <ContegBreadcrumbs
        items={items}
        navigate={(to: Breadcrumb) => navigate(to.url)}
      />
    </ConditionalWrapper>
  );
};

export default Breadcrumbs;
