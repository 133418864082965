import { useAuth, useAutoInitLogin } from '@conteg/auth';
import { Button, Flex, Loading, Text } from '@conteg/ui';
import Notification from 'components/notification/notification';
import { CUSTOM_AUTH_TOKEN } from 'config';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from 'utils/routing/routes';

const LoginWeb = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { signinRedirect } = useAuth();

  const { isLoading, error } = useAutoInitLogin(
    appRoutes.home,
    navigate,
    !!CUSTOM_AUTH_TOKEN
  );

  if (isLoading) {
    return (
      <Flex alignItems="center" justifyContent="center">
        <Loading text={t('Page.Login.Loading')} />
      </Flex>
    );
  }

  return (
    <Flex flexDirection="column" alignItems="center" padding="2rem" gap="1rem">
      {error ? (
        <Notification testId="login" message="Page.LoginWeb.LoginError" />
      ) : (
        <Text
          testId="login-info"
          content={t('Page.Login.Info')}
          variant="normal"
        />
      )}
      <Button
        testId="login-button"
        title={t('Page.Login.LoginButton')}
        size="xl"
        onClick={() => signinRedirect()}
      />
    </Flex>
  );
};

export default LoginWeb;
