import { useUserData } from '@conteg/auth';
import {
  Button,
  Heading,
  Option,
  showAlertModalError,
  styled,
} from '@conteg/ui';
import { FormCheckbox } from 'components/inputs/form-checkbox';
import Notification from 'components/notification/notification';
import { Select } from 'components/select/select';
import { CUSTOM_AUTH_TOKEN } from 'config';
import i18n from 'i18next';
import { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ServiceType,
  ServiceTypeEnum,
  StorageUnitDocument,
  UpdateContentStateEnum,
  useUpdateContentStateMutation,
} from 'types/generated/graphql';
import { usePoint } from 'utils/device/device-store';
import { useInvalidateQueries } from 'utils/hooks/use-ivalidate-queries';

type FormInputs = {
  state: UpdateContentStateEnum.PickUp | UpdateContentStateEnum.StockOut;
  isPickedUpBySender: boolean;
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
  margin-top: 5rem;
`;

const getOptions = (
  serviceType: ServiceTypeEnum | ServiceType | undefined,
  isDeliverySubmission: boolean | undefined
) => {
  let options: Option[] = [
    {
      label: i18n.t(
        'Page.StorageUnit.Actions.UpdateReservationState.Select.Option.Handover'
      ),
      value: UpdateContentStateEnum.PickUp,
    },
  ];

  if (serviceType === ServiceTypeEnum.Delivery) {
    options.push({
      label: i18n.t(
        'Page.StorageUnit.Actions.UpdateReservationState.Select.Option.StockOut'
      ),
      value: UpdateContentStateEnum.StockOut,
    });
  }

  if (isDeliverySubmission) {
    options = options.filter(
      (option) => option.value !== UpdateContentStateEnum.PickUp
    );
  }

  return options;
};

type UpdateReservationStateProps = {
  tenantSubjectId: string;
  trackingIdentifier?: string | null | undefined;
  contentId: string;
  serviceType?: ServiceTypeEnum | ServiceType;
  isDeliverySubmission: boolean | undefined;
  storageUnitId: string;
};

const UpdateReservationState = ({
  trackingIdentifier,
  tenantSubjectId,
  contentId,
  serviceType,
  isDeliverySubmission,
  storageUnitId,
}: UpdateReservationStateProps) => {
  const { t } = useTranslation();
  const { pointId } = usePoint();
  const userData = useUserData(CUSTOM_AUTH_TOKEN);
  const userId = userData?.userId;

  const invalidate = useInvalidateQueries();

  const [formNotice, setFormNotice] = useState<string>('');

  const { mutate: updateReservationState, isPending } =
    useUpdateContentStateMutation();

  const formActions = useForm<FormInputs>({
    defaultValues: {
      state: undefined,
    },
  });

  const stateWatch = formActions.watch('state');

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = formActions;

  const handleUpdateReservationState: SubmitHandler<FormInputs> = ({
    state,
    isPickedUpBySender,
  }) => {
    setFormNotice('');
    updateReservationState(
      {
        pointId: pointId as string,
        tenantSubjectId,
        contentId,
        trackingIdentifier: trackingIdentifier ?? null,
        state,
        storageUnitId,
        userId,
        isPickedUpBySender:
          serviceType == ServiceType.HandToHand && isPickedUpBySender,
      },
      {
        onSuccess: () => (
          setFormNotice(
            'Page.StorageUnit.Actions.UpdateReservationState.Success'
          ),
          invalidate([StorageUnitDocument]),
          reset()
        ),
        onError: (error) =>
          showAlertModalError(
            t('Page.StorageUnit.Actions.UpdateReservationState.Error'),
            error
          ),
      }
    );
  };

  return (
    <FormProvider {...formActions}>
      <Form onSubmit={handleSubmit(handleUpdateReservationState)}>
        <Heading
          variant="h2"
          title={t('Page.StorageUnit.Actions.UpdateReservationState.Heading')}
        />
        {formNotice && (
          <Notification
            testId="update-reservation-state-notification"
            message={formNotice}
          />
        )}
        <Notification
          testId="update-reservation-state-notification-warning"
          message="Page.StorageUnit.Actions.UpdateReservationState.Warning"
        />
        <Select
          name="state"
          options={getOptions(serviceType, isDeliverySubmission)}
          label={t(
            'Page.StorageUnit.Actions.UpdateReservationState.Select.Text'
          )}
          isNullable={false}
          required
          error={errors?.state || undefined}
        />
        {serviceType == ServiceType.HandToHand &&
          stateWatch == UpdateContentStateEnum.PickUp && (
            <FormCheckbox
              name="isPickedUpBySender"
              testId="is-picked-up-by-sender-checkbox"
              label={t(
                'Page.StorageUnit.Actions.UpdateReservationState.IsPickedUpBySender'
              )}
            />
          )}
        <Button
          title={t('Page.StorageUnit.Actions.UpdateReservationState.Button')}
          testId="open-without-pin-button"
          variant="primary"
          size="xl"
          disabled={isPending}
        />
      </Form>
    </FormProvider>
  );
};

export default UpdateReservationState;
